import React from "react";
import FormPage from "../Pages/Homepage/FormPage";
import "./Blog.css"; // Link to updated CSS styles

const Balancingjobsandstudy = () => {
  return (
    <div className="blog-container">
      {/* Top Section */}
      <div className="header-section">
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/b21.jpg`}
          alt="Consultancy"
          className="blog-header-image"
        />
        <div className="blog-header-text">
          <h1>Why We Are The Best Consultancy</h1>
          <p>
            Asian Education Consultancy - Guiding Students to Their Dream
            Universities Across the Globe
          </p>
        </div>
      </div>

      {/* Blog Section */}
      <div className="blog-content">
        <div className="blog-date">
          <span>October 16, 2024</span>
        </div>

        <div className="blog-main">
          <h2>Studying Abroad</h2>
          <p>
            Studying abroad is a dream for many Nepali students, offering a
            pathway to global opportunities and advanced education. However, the
            journey from dreaming to reality requires careful planning, test
            preparation, and the right guidance. Asian Education stands out as
            one of the best consultancies in Nepal for students who aspire to
            study in top universities worldwide. Here’s how Asian Education can
            help you achieve your study abroad goals and make the process
            smoother and more effective.
          </p>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/aus.jpeg`}
            alt="Blog image"
            className="blog-main-image"
          />
        </div>

        {/* Additional Content - Inserted Section */}
        <div className="additional-section">
          <h3>Balancing Studies and Part-Time Work: Study Abroad Tips for Nepali Students</h3>
          <p>
            Overseas education is a fruitful process that encompasses a great number of
            possibilities for a student’s further development. Many Nepali students have
            part-time jobs to manage their own expenses aside from their classes. Working
            while studying is not easy, but with good time management, one can excel in both.
            Here are some strategies for successfully balancing work and academics.
          </p>

          <h3>Balance</h3>
          <p>
            Balancing studies and work is crucial for several reasons:
            <ul>
              <li>Academic Success: Managing your working schedule helps you allocate time to your studies.</li>
              <li>Financial Stability: A part-time job helps cover living expenses without putting financial strain.</li>
              <li>Skill Development: Working while studying enhances time management and organizational skills.</li>
              <li>Networking: Part-time jobs offer valuable networking opportunities in the workplace.</li>
            </ul>
          </p>

          <h3>Selecting the Proper Type of Part-Time Employment</h3>
          <p>
            It is important to choose a part-time job that does not interfere with your studies.
            <ul>
              <li>Flexibility: Look for jobs that align with your class schedule, such as on-campus jobs.</li>
              <li>Relevance: If possible, find a job related to your field of study to gain experience.</li>
              <li>Proximity: Find jobs near your campus or home to save on commuting time.</li>
              <li>Workload: Avoid jobs that demand too much time and energy, affecting your academic performance.</li>
            </ul>
          </p>

          <h3>Time Management and Balancing Tips</h3>
          <p>
            Time management is critical for alternating between studying and working.
            <ul>
              <li>Create a Schedule: Plan out your week, balancing classes, study time, work, and leisure.</li>
              <li>Prioritize Tasks: Tackle your academic tasks first before focusing on work-related chores.</li>
              <li>Use a Planner: Keep track of assignments, deadlines, and work shifts using a planner or calendar.</li>
              <li>Set Goals: Break down large tasks into manageable goals for each week to stay focused.</li>
              <li>Avoid Procrastination: Complete tasks promptly to avoid last-minute stress and subpar work.</li>
            </ul>
          </p>

          <h3>Maintaining Academic Performance</h3>
          <p>
            Give your academics top priority while holding a part-time job. Here’s how to stay on top of your studies:
            <ul>
              <li>Stay Engaged in Class: Attend all classes, participate actively, and take thorough notes.</li>
              <li>Utilize Campus Resources: Take advantage of tutorials, study groups, and library resources.</li>
              <li>Communicate with Professors: Keep your professors informed about your work schedule if needed.</li>
              <li>Study Smart: Use effective study techniques to make the most of your limited study time.</li>
              <li>Take Care of Your Health: Ensure you rest, eat well, exercise, and take breaks when needed.</li>
            </ul>
          </p>

          <h3>Balancing Work Commitments</h3>
          <p>
            Balancing work is equally important. Here are tips for managing work alongside academics:
            <ul>
              <li>Communicate with Your Employer: Ensure your employer knows your academic schedule.</li>
              <li>Be Reliable: Punctuality and professionalism at work can lead to better job opportunities.</li>
              <li>Learn to Say No: Politely decline extra shifts if they interfere with your studies.</li>
              <li>Seek Support: Ask for help from colleagues, supervisors, or counselors when needed.</li>
              <li>Make the Most of Downtime: Use spare time at work to review class notes or plan your studies.</li>
            </ul>
          </p>
        </div>

        {/* Bottom Section */}
        <div className="bottom-section">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/a6.Webp`}
            alt="Additional image"
            className="bottom-image"
          />
          <h3>Conclusion</h3>
          <p>
            Balancing studies and part-time work is challenging but achievable with good time management,
            proper job selection, and a focus on academic performance. With the right strategies in place,
            you can enjoy a fulfilling and enriching study abroad experience. Asian Education is here to guide
            you through every step of the way, ensuring you succeed both academically and professionally.
          </p>
        </div>
      </div>

      {/* Footer */}
      <div className="footer">
        <FormPage />
      </div>
    </div>
  );
};

export default Balancingjobsandstudy;
