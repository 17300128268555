import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faDollarSign,
  faEarth,
  faFileAlt,
  faMapMarkerAlt,
  faStar,
  faBook,
} from "@fortawesome/free-solid-svg-icons";
import Footer from "../../../Homepage/Footer";
import FormPage from "../../../Homepage/FormPage";

const courses = [
  //for Certified//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  {
    title: "Business and Management",
    university: "Southern Cross University",
    location: "Perth & Sydney, Australia",
    worldRanking: "651-700 globally",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Business and Management",
    university: "RMIT University",
    location: "Melbourne, Australia",
    worldRanking: "385",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Business and Management",
    university: "Queensland University of Technology",
    location: "Brisbane, Australia",
    worldRanking: "148",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Business and Management",
    university: "Murdoch University",
    location: "Perth, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Business and Management",
    university: "Monash University",
    location: "Melbourne, Australia",
    worldRanking: "54",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Business and Management",
    university: "Latrobe University",
    location: "Sydney, Australia",
    worldRanking: "409",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Business and Management",
    university: "James Cook University",
    location: "Brisbane, Australia",
    worldRanking: "554",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Business and Management",
    university: "Flinders University",
    location: "Adelaide, Australia",
    worldRanking: "474",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Business and Management",
    university: "Edith Cowan University",
    location: "Perth, Australia",
    worldRanking: "651-700 globally",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Business and Management",
    university: "Deakin University",
    location: "Melbourne, Australia",
    worldRanking: " 333rd globally",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Business and Management",
    university: "Curtin University",
    location: "Perth, Australia",
    worldRanking: " 201-250th globally​",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Business and Management",
    university: "CQ University",
    location: "Adelaide, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Business and Management",
    university: "Charles Sturt University ",
    location: "Sydney, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Business and Management",
    university: "Charles Darwin University",
    location: "Sydney, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Business and Management",
    university: "Australian National University",
    location: "Canberra, Australia",
    worldRanking: "67",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
 
  {
    title: "Business and Management",
    university: "Western Sydney University",
    location: "Sydney, Australia",
    worldRanking: "143",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Business and Management",
    university: " Victoria University ",
    location: "Sydney, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Business and Management",
    university: " University of Wollongong",
    location: "Sydney, Australia",
    worldRanking: "370",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Business and Management",
    university: "University of Western Australia",
    location: "Perth, Australia",
    worldRanking: "143",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Business and Management",
    university: "University of the Sunshine Coast",
    location: "Adelaide, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Business and Management",
    university: "University of Technology Sydney",
    location: "Sydney, Australia",
    worldRanking: "148",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Business and Management",
    university: "Swinburne University of Technology",
    location: "Melbourne, Australia",
    worldRanking: "590",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Business and Management",
    university: "Torrens University Australia",
    location: "Adelaide, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Business and Management",
    university: "University of Adelaide",
    location: "Adelaide, Australia",
    worldRanking: "111",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Business and Management",
    university: "University of Melbourne",
    location: "Melbourne, Australia",
    worldRanking: "37",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Business and Management",
    university: "University of Canberra",
    location: "Canberra, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Business and Management",
    university: "University of New England",
    location: "Sydney, Australia",
    worldRanking: "651",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Business and Management",
    university: "University of Newcastle",
    location: "Sydney, Australia",
    worldRanking: "427",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Business and Management",
    university: "University of Queensland ",
    location: "Brisbane, Australia",
    worldRanking: "70",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Business and Management",
    university: "University of South Australia",
    location: "Adelaide, Australia",
    worldRanking: "472",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Business and Management",
    university: "University of Southern Queensland",
    location: "Brisbane, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Business and Management",
    university: "University of Tasmania",
    location: "Melbourne, Australia",
    worldRanking: "417",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  //scienceandtechnology////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  {
    title: "Science and Technology",
    university: "Victoria University",
    location: "Sydney, Australia",
    worldRanking: "50",
    Certified: "Master",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/masterofdesign",
  },
  {
    title: "Science and Technology",
    university: "Southern Cross University",
    location: "Perth & Sydney, Australia",
    worldRanking: "651-700 globally",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Science and Technology",
    university: "Australian Catholic University",
    location: "Sydney, Australia",
    worldRanking: " 637",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "$29,664",
    link: "/bachelorofdesign",
  },
  {
    title: "Science and Technology",
    university: "RMIT University",
    location: "Melbourne, Australia",
    worldRanking: "385",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Science and Technology",
    university: "Queensland University of Technology",
    location: "Brisbane, Australia",
    worldRanking: "148",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Science and Technology",
    university: "Murdoch University",
    location: "Perth, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Science and Technology",
    university: "Monash University",
    location: "Melbourne, Australia",
    worldRanking: "54",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Science and Technology",
    university: "Latrobe University",
    location: "Sydney, Australia",
    worldRanking: "409",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Science and Technology",
    university: "James Cook University",
    location: "Brisbane, Australia",
    worldRanking: "554",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Science and Technology",
    university: "Flinders University",
    location: "Adelaide, Australia",
    worldRanking: "474",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Science and Technology",
    university: "Edith Cowan University",
    location: "Perth, Australia",
    worldRanking: "651-700 globally",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Science and Technology",
    university: "Deakin University",
    location: "Melbourne, Australia",
    worldRanking: " 333rd globally",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Science and Technology",
    university: "Curtin University",
    location: "Perth, Australia",
    worldRanking: " 201-250th globally​",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Science and Technology",
    university: "CQ University",
    location: "Adelaide, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Science and Technology",
    university: "Charles Sturt University ",
    location: "Sydney, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Science and Technology",
    university: "Charles Darwin University",
    location: "Sydney, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Science and Technology",
    university: "Australian National University",
    location: "Canberra, Australia",
    worldRanking: "67",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

  {
    title: "Science and Technology",
    university: "Western Sydney University",
    location: "Sydney, Australia",
    worldRanking: "143",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Science and Technology",
    university: " Victoria University ",
    location: "Sydney, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Science and Technology",
    university: " University of Wollongong",
    location: "Sydney, Australia",
    worldRanking: "370",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Science and Technology",
    university: "University of Western Australia",
    location: "Perth, Australia",
    worldRanking: "143",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Science and Technology",
    university: "University of the Sunshine Coast",
    location: "Adelaide, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Science and Technology",
    university: "University of Technology Sydney",
    location: "Sydney, Australia",
    worldRanking: "148",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Science and Technology",
    university: "Swinburne University of Technology",
    location: "Melbourne, Australia",
    worldRanking: "590",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Science and Technology",
    university: "Torrens University Australia",
    location: "Adelaide, Australiaa",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Science and Technology",
    university: "University of Adelaide",
    location: "Adelaide, Australia",
    worldRanking: "111",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Science and Technology",
    university: "University of Melbourne",
    location: "Melbourne, Australia",
    worldRanking: "37",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Science and Technology",
    university: "University of Canberra",
    location: "Canberra, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Science and Technology",
    university: "University of New England",
    location: "Sydney, Australia",
    worldRanking: "651",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Science and Technology",
    university: "University of Newcastle",
    location: "Sydney, Australia",
    worldRanking: "427",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Science and Technology",
    university: "University of Queensland ",
    location: "Brisbane, Australia",
    worldRanking: "70",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Science and Technology",
    university: "University of South Australia",
    location: "Adelaide, Australia",
    worldRanking: "472",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Science and Technology",
    university: "University of Southern Queensland",
    location: "Brisbane, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Science and Technology",
    university: "University of Tasmania",
    location: "Melbourne, Australia",
    worldRanking: "417",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

  /////////Engineering///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  {
    title: "Engineering",
    university: "Victoria University",
    location: "Sydney, Australia",
    worldRanking: "50",
    Certified: "Master",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/masterofdesign",
  },
  {
    title: "Engineering",
    university: "Southern Cross University",
    location: "Perth & Sydney, Australia",
    worldRanking: "651-700 globally",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Engineering",
    university: "Australian Catholic University",
    location: "Sydney, Australia",
    worldRanking: " 637",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Engineering",
    university: "RMIT University",
    location: "Melbourne, Australia",
    worldRanking: "385",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Engineering",
    university: "Queensland University of Technology",
    location: "Brisbane, Australia",
    worldRanking: "148",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Engineering",
    university: "Murdoch University",
    location: "Perth, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Engineering",
    university: "Monash University",
    location: "Melbourne, Australia",
    worldRanking: "54",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Engineering",
    university: "Latrobe University",
    location: "Sydney, Australia",
    worldRanking: "409",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Engineering",
    university: "James Cook University",
    location: "Brisbane, Australia",
    worldRanking: "554",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Engineering",
    university: "Flinders University",
    location: "Adelaide, Australia",
    worldRanking: "474",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Engineering",
    university: "Edith Cowan University",
    location: "Perth, Australia",
    worldRanking: "651-700 globally",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Engineering",
    university: "Deakin University",
    location: "Melbourne, Australia",
    worldRanking: " 333rd globally",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Engineering",
    university: "Curtin University",
    location: "Perth, Australia",
    worldRanking: " 201-250th globally​",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Engineering",
    university: "CQ University",
    location: "Adelaide, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Engineering",
    university: "Charles Sturt University ",
    location: "Sydney, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Engineering",
    university: "Charles Darwin University",
    location: "Sydney, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Engineering",
    university: "Australian National University",
    location: "Canberra, Australia",
    worldRanking: "67",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

  {
    title: "Engineering",
    university: "Western Sydney University",
    location: "Sydney, Australia",
    worldRanking: "143",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Engineering",
    university: " Victoria University ",
    location: "Sydney, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Engineering",
    university: " University of Wollongong",
    location: "Sydney, Australia",
    worldRanking: "370",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Engineering",
    university: "University of Western Australia",
    location: "Perth, Australia",
    worldRanking: "143",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Engineering",
    university: "University of the Sunshine Coast",
    location: "Adelaide, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Engineering",
    university: "University of Technology Sydney",
    location: "Sydney, Australia",
    worldRanking: "148",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Engineering",
    university: "Swinburne University of Technology",
    location: "Melbourne, Australia",
    worldRanking: "590",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Engineering",
    university: "Torrens University Australia",
    location: "Adelaide, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Engineering",
    university: "University of Adelaide",
    location: "Adelaide, Australia",
    worldRanking: "111",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Engineering",
    university: "University of Melbourne",
    location: "Melbourne, Australia",
    worldRanking: "37",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Engineering",
    university: "University of Canberra",
    location: "Canberra, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Engineering",
    university: "University of New England",
    location: "Sydney, Australia",
    worldRanking: "651",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Engineering",
    university: "University of Newcastle",
    location: "Sydney, Australia",
    worldRanking: "427",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Engineering",
    university: "University of Queensland ",
    location: "Brisbane, Australia",
    worldRanking: "70",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Engineering",
    university: "University of South Australia",
    location: "Adelaide, Australia",
    worldRanking: "472",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Engineering",
    university: "University of Southern Queensland",
    location: "Brisbane, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Engineering",
    university: "University of Tasmania",
    location: "Melbourne, Australia",
    worldRanking: "417",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  ////////////////////Life Science////////////////////////////////////////////////////////////////////////////////
  {
    title: "Life Science",
    university: "Victoria University",
    location: "Sydney, Australia",
    worldRanking: "50",
    Certified: "Master",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/masterofdesign",
  },
  {
    title: "Life Science",
    university: "Southern Cross University",
    location: "Perth & Sydney, Australia",
    worldRanking: "651-700 globally",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Life Science",
    university: "Australian Catholic University",
    location: "Sydney, Australia",
    worldRanking: " 637",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Life Science",
    university: "RMIT University",
    location: "Melbourne, Australia",
    worldRanking: "385",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Life Science",
    university: "Queensland University of Technology",
    location: "Brisbane, Australia",
    worldRanking: "148",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Life Science",
    university: "Murdoch University",
    location: "Perth, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Life Science",
    university: "Monash University",
    location: "Melbourne, Australia",
    worldRanking: "54",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Life Science",
    university: "Latrobe University",
    location: "Sydney, Australia",
    worldRanking: "409",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Life Science",
    university: "James Cook University",
    location: "Brisbane, Australia",
    worldRanking: "554",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Life Science",
    university: "Flinders University",
    location: "Adelaide, Australia",
    worldRanking: "474",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Life Science",
    university: "Edith Cowan University",
    location: "Perth, Australia",
    worldRanking: "651-700 globally",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Life Science",
    university: "Deakin University",
    location: "Melbourne, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Life Science",
    university: "Curtin University",
    location: "Perth, Australia",
    worldRanking: " 201-250th globally​",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Life Science",
    university: "CQ University",
    location: "Adelaide, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Life Science",
    university: "Charles Sturt University ",
    location: "Sydney, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Life Science",
    university: "Charles Darwin University",
    location: "Sydney, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Life Science",
    university: "Australian National University",
    location: "Canberra, Australia",
    worldRanking: "67",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  ////
  {
    title: "Life Science",
    university: "Western Sydney University",
    location: "Sydney, Australia",
    worldRanking: "143",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Life Science",
    university: " Victoria University ",
    location: "Sydney, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Life Science",
    university: " University of Wollongong",
    location: "Sydney, Australia",
    worldRanking: "370",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Life Science",
    university: "University of Western Australia",
    location: "Perth, Australia",
    worldRanking: "143",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Life Science",
    university: "University of the Sunshine Coast",
    location: "Adelaide, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Life Science",
    university: "University of Technology Sydney",
    location: "Sydney, Australia",
    worldRanking: "148",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Life Science",
    university: "Swinburne University of Technology",
    location: "Melbourne, Australia",
    worldRanking: "590",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Life Science",
    university: "Torrens University Australia",
    location: "Adelaide, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Life Science",
    university: "University of Adelaide",
    location: "Adelaide, Australia",
    worldRanking: "111",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Life Science",
    university: "University of Melbourne",
    location: "Melbourne, Australia",
    worldRanking: "37",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Life Science",
    university: "University of Canberra",
    location: "Canberra, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Life Science",
    university: "University of New England",
    location: "Sydney, Australia",
    worldRanking: "651",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Life Science",
    university: "University of Newcastle",
    location: "Sydney, Australia",
    worldRanking: "427",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Life Science",
    university: "University of Queensland ",
    location: "Brisbane, Australia",
    worldRanking: "70",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Life Science",
    university: "University of South Australia",
    location: "Adelaide, Australia",
    worldRanking: "472",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Life Science",
    university: "University of Southern Queensland",
    location: "Brisbane, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Life Science",
    university: "University of Tasmania",
    location: "Melbourne, Australia",
    worldRanking: "417",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

  /////////////////Medical and Healthcare///////////////////////////////////////////////////
  {
    title: "Medical and Healthcare",
    university: "Victoria University",
    location: "Sydney, Australia",
    worldRanking: "50",
    Certified: "Master",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/masterofdesign",
  },
  {
    title: "Medical and Healthcare",
    university: "Southern Cross University",
    location: "Perth & Sydney, Australia",
    worldRanking: "651-700 globally",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Medical and Healthcare",
    university: "Australian Catholic University",
    location: "Sydney, Australia",
    worldRanking: " 637",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Medical and Healthcare",
    university: "RMIT University",
    location: "Melbourne, Australia",
    worldRanking: "385",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Medical and Healthcare",
    university: "Queensland University of Technology",
    location: "Brisbane, Australia",
    worldRanking: "148",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Medical and Healthcare",
    university: "Murdoch University",
    location: "Perth, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Medical and Healthcare",
    university: "Monash University",
    location: "Melbourne, Australia",
    worldRanking: "54",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Medical and Healthcare",
    university: "Latrobe University",
    location: "Sydney, Australia",
    worldRanking: "409",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Medical and Healthcare",
    university: "James Cook University",
    location: "Brisbane, Australia",
    worldRanking: "554",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Medical and Healthcare",
    university: "Flinders University",
    location: "Adelaide, Australia",
    worldRanking: "474",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Medical and Healthcare",
    university: "Edith Cowan University",
    location: "Perth, Australia",
    worldRanking: "651-700 globally",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Medical and Healthcare",
    university: "Deakin University",
    location: "Melbourne, Australia",
    worldRanking: " 333rd globally",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Medical and Healthcare",
    university: "Curtin University",
    location: "Perth, Australia",
    worldRanking: " 201-250th globally​",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Medical and Healthcare",
    university: "CQ University",
    location: "Adelaide, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Medical and Healthcare",
    university: "Charles Sturt University ",
    location: "Sydney, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Medical and Healthcare",
    university: "Charles Darwin University",
    location: "Sydney, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Medical and Healthcare",
    university: "Australian National University",
    location: "Canberra, Australia",
    worldRanking: "67",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Medical and Healthcare",
    university: "Western Sydney University",
    location: "Sydney, Australia",
    worldRanking: "143",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Medical and Healthcare",
    university: " Victoria University ",
    location: "Sydney, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Medical and Healthcare",
    university: " University of Wollongong",
    location: "Sydney, Australia",
    worldRanking: "370",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Medical and Healthcare",
    university: "University of Western Australia",
    location: "Perth, Australia",
    worldRanking: "143",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Medical and Healthcare",
    university: "University of the Sunshine Coast",
    location: "Adelaide, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Medical and Healthcare",
    university: "University of Technology Sydney",
    location: "Sydney, Australia",
    worldRanking: "148",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Medical and Healthcare",
    university: "Swinburne University of Technology",
    location: "Melbourne, Australia",
    worldRanking: "590",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Medical and Healthcare",
    university: "Torrens University Australia",
    location: "Adelaide, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Medical and Healthcare",
    university: "University of Adelaide",
    location: "Adelaide, Australia",
    worldRanking: "111",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Medical and Healthcare",
    university: "University of Melbourne",
    location: "Melbourne, Australia",
    worldRanking: "37",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Medical and Healthcare",
    university: "University of Canberra",
    location: "Canberra, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Medical and Healthcare",
    university: "University of New England",
    location: "Sydney, Australia",
    worldRanking: "651",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Medical and Healthcare",
    university: "University of Newcastle",
    location: "Sydney, Australia",
    worldRanking: "427",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Medical and Healthcare",
    university: "University of Queensland ",
    location: "Brisbane, Australia",
    worldRanking: "70",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Medical and Healthcare",
    university: "University of South Australia",
    location: "Adelaide, Australia",
    worldRanking: "472",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Medical and Healthcare",
    university: "University of Southern Queensland",
    location: "Brisbane, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Medical and Healthcare",
    university: "University of Tasmania",
    location: "Melbourne, Australia",
    worldRanking: "417",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  //////////////////////Arts///////////////////////////////////////////////
  {
    title: "Arts",
    university: "Victoria University",
    location: "Sydney, Australia",
    worldRanking: "50",
    Certified: "Master",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/masterofdesign",
  },
  {
    title: "Arts",
    university: "Southern Cross University",
    location: "Perth & Sydney, Australia",
    worldRanking: "651-700 globally",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Arts",
    university: "Australian Catholic University",
    location: "Sydney, Australia",
    worldRanking: " 637",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Arts",
    university: "RMIT University",
    location: "Melbourne, Australia",
    worldRanking: "385",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Arts",
    university: "Queensland University of Technology",
    location: "Brisbane, Australia",
    worldRanking: "148",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Arts",
    university: "Murdoch University",
    location: "Perth, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Arts",
    university: "Monash University",
    location: "Melbourne, Australia",
    worldRanking: "54",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Arts",
    university: "Latrobe University",
    location: "Sydney, Australia",
    worldRanking: "409",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Arts",
    university: "James Cook University",
    location: "Brisbane, Australia",
    worldRanking: "554",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Arts",
    university: "Flinders University",
    location: "Adelaide, Australia",
    worldRanking: "474",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Arts",
    university: "Edith Cowan University",
    location: "Perth, Australia",
    worldRanking: "651-700 globally",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Arts",
    university: "Deakin University",
    location: "Melbourne, Australia",
    worldRanking: " 333rd globally",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Arts",
    university: "Curtin University",
    location: "Perth, Australia",
    worldRanking: " 201-250th globally​",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Arts",
    university: "CQ University",
    location: "Adelaide, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Arts",
    university: "Charles Sturt University ",
    location: "Sydney, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Arts",
    university: "Charles Darwin University",
    location: "Sydney, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Arts",
    university: "Australian National University",
    location: "Canberra, Australia",
    worldRanking: "67",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Arts",
    university: "Western Sydney University",
    location: "Sydney, Australia",
    worldRanking: "143",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Arts",
    university: " Victoria University ",
    location: "Sydney, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Arts",
    university: " University of Wollongong",
    location: "Sydney, Australia",
    worldRanking: "370",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Arts",
    university: "University of Western Australia",
    location: "Perth, Australia",
    worldRanking: "143",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Arts",
    university: "University of the Sunshine Coast",
    location: "Adelaide, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Arts",
    university: "University of Technology Sydney",
    location: "Sydney, Australia",
    worldRanking: "148",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Arts",
    university: "Swinburne University of Technology",
    location: "Melbourne, Australia",
    worldRanking: "590",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Arts",
    university: "Torrens University Australia",
    location: "Adelaide, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Arts",
    university: "University of Adelaide",
    location: "Adelaide, Australia",
    worldRanking: "111",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Arts",
    university: "University of Melbourne",
    location: "Melbourne, Australia",
    worldRanking: "37",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Arts",
    university: "University of Canberra",
    location: "Canberra, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Arts",
    university: "University of New England",
    location: "Sydney, Australia",
    worldRanking: "651",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Arts",
    university: "University of Newcastle",
    location: "Sydney, Australia",
    worldRanking: "427",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Arts",
    university: "University of Queensland ",
    location: "Brisbane, Australia",
    worldRanking: "70",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Arts",
    university: "University of South Australia",
    location: "Adelaide, Australia",
    worldRanking: "472",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Arts",
    university: "University of Southern Queensland",
    location: "Brisbane, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Arts",
    university: "University of Tasmania",
    location: "Melbourne, Australia",
    worldRanking: "417",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  /////////////////////////Law///////////////////////////////////////////
  {
    title: "Law",
    university: "Victoria University",
    location: "Sydney, Australia",
    worldRanking: "50",
    Certified: "Master",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/masterofdesign",
  },
  {
    title: "Law",
    university: "Southern Cross University",
    location: "Perth & Sydney, Australia",
    worldRanking: "651-700 globally",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Law",
    university: "Australian Catholic University",
    location: "Sydney, Australia",
    worldRanking: " 637",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Law",
    university: "RMIT University",
    location: "Melbourne, Australia",
    worldRanking: "385",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Law",
    university: "Queensland University of Technology",
    location: "Brisbane, Australia",
    worldRanking: "148",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Law",
    university: "Murdoch University",
    location: "Perth, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Law",
    university: "Monash University",
    location: "Melbourne, Australia",
    worldRanking: "54",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Law",
    university: "Latrobe University",
    location: "Sydney, Australia",
    worldRanking: "409",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Law",
    university: "James Cook University",
    location: "Brisbane, Australia",
    worldRanking: "554",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Law",
    university: "Flinders University",
    location: "Adelaide, Australia",
    worldRanking: "474",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Law",
    university: "Edith Cowan University",
    location: "Perth, Australia",
    worldRanking: "651-700 globally",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Law",
    university: "Deakin University",
    location: "Melbourne, Australia",
    worldRanking: " 333rd globally",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Law",
    university: "Curtin University",
    location: "Perth, Australia",
    worldRanking: " 201-250th globally​",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Law",
    university: "CQ University",
    location: "Adelaide, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Law",
    university: "Charles Sturt University ",
    location: "Sydney, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Law",
    university: "Charles Darwin University",
    location: "Sydney, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Law",
    university: "Australian National University",
    location: "Canberra, Australia",
    worldRanking: "67",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Law",
    university: "Western Sydney University",
    location: "Sydney, Australia",
    worldRanking: "143",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Law",
    university: " Victoria University ",
    location: "Sydney, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Law",
    university: " University of Wollongong",
    location: "Sydney, Australia",
    worldRanking: "370",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Law",
    university: "University of Western Australia",
    location: "Perth, Australia",
    worldRanking: "143",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Law",
    university: "University of the Sunshine Coast",
    location: "Adelaide, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Law",
    university: "University of Technology Sydney",
    location: "Sydney, Australia",
    worldRanking: "148",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Law",
    university: "Swinburne University of Technology",
    location: "Melbourne, Australia",
    worldRanking: "590",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Law",
    university: "Torrens University Australia",
    location: "Adelaide, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Law",
    university: "University of Adelaide",
    location: "Adelaide, Australia",
    worldRanking: "111",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Law",
    university: "University of Melbourne",
    location: "Melbourne, Australia",
    worldRanking: "37",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Law",
    university: "University of Canberra",
    location: "Canberra, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Law",
    university: "University of New England",
    location: "Sydney, Australia",
    worldRanking: "651",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Law",
    university: "University of Newcastle",
    location: "Sydney, Australia",
    worldRanking: "427",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Law",
    university: "University of Queensland ",
    location: "Brisbane, Australia",
    worldRanking: "70",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Law",
    university: "University of South Australia",
    location: "Adelaide, Australia",
    worldRanking: "472",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Law",
    university: "University of Southern Queensland",
    location: "Brisbane, Australia",
    worldRanking: "50",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
  {
    title: "Law",
    university: "University of Tasmania",
    location: "Melbourne, Australia",
    worldRanking: "417",
    Certified: "Certified",
    intakeDate: "04.09.2024",
    entryScore: "IELTS 6.5",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

  // Other programs
];

const CourseAustralia = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [filteredPrograms, setFilteredPrograms] = useState([]);

  useEffect(() => {
    const searchCriteria = location.state || {};
    const filtered = courses.filter(
      (program) =>
        (!searchCriteria.course ||
          program.title.toLowerCase().includes(searchCriteria.course?.toLowerCase()))
    );

    setFilteredPrograms(filtered.length > 0 ? filtered : null);
  }, [location.state]);

  const handleViewDetails = (course) => {
    navigate("/course-detail", {
      state: {
        courseName: course.title,
        universityName: course.university,
        countryName: course.location.trim(),
        worldRanking: course.worldRanking,
        degree: course.degree,
        intakeDate: course.intakeDate,
        entryScore: course.entryScore,
        fees: course.fees,
      },
    });
  };

  const handleContactAIEC = () => {
    navigate("/formpage");
  };

  return (
    <>
      <Container>
        {filteredPrograms ? (
          <>
            <DataCount size="1.5em" bold>
              Found {filteredPrograms.length} programs according to your criteria in Australia.
            </DataCount>
            <GridContainer>
              {filteredPrograms.map((program, index) => (
                <ProgramCard key={index}>
                  <ProgramTitle>{program.university}</ProgramTitle>
                  <ProgramDetails>
                    <p>
                      <FontAwesomeIcon icon={faBook} />{" "}
                      <strong>{program.title}</strong>
                    </p>
                    {/* <p>
                      <FontAwesomeIcon icon={faFileAlt} /> {program.Certified}
                    </p> */}
                    <p>
                      <FontAwesomeIcon icon={faMapMarkerAlt} /> {program.location}
                    </p>
                    <p>
                      <FontAwesomeIcon icon={faEarth} /> World Ranking:{" "}
                      {program.worldRanking}
                    </p>
                    <p>
                      <FontAwesomeIcon icon={faCalendarAlt} />  Intake:{" "}
                      {program.intakeDate}
                    </p>
                    <p>
                      <FontAwesomeIcon icon={faStar} /> Entry Score:{" "}
                      {program.entryScore}
                    </p>
                    <p>
                      <FontAwesomeIcon icon={faDollarSign} /> Fees: 
                      {/* <ContactLink onClick={handleContactClick}>
                        {program.fees}
                      </ContactLink> */}
                    </p>
                  </ProgramDetails>
                  <ViewDetailsButton onClick={() => handleViewDetails(program)}>
              View details
            </ViewDetailsButton>
          </ProgramCard>
              ))}
            </GridContainer>
          </>
        ) : (
          <DataCount size="1.5em" bold>
            No available programs for the selected criteria.
          </DataCount>
        )}
      </Container>

    </>
  );
};

// Styled-components for styling
const Container = styled.div`
  margin-top: 100px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DataCount = styled.p`
  font-size: ${(props) => props.size || "1.1em"};
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  margin-bottom: 20px;
  text-align: center;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  justify-items: center;
`;

const ProgramCard = styled.div`
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  max-width: 350px;
`;

const ProgramTitle = styled.h3`
  font-size: 1em;
  color: #333;
  margin-bottom: 10px;
`;

const ProgramDetails = styled.div`
  text-align: left;
  margin-top: 10px;

  p {
    margin: 4px 0;
    font-size: 0.8em;
    color: #555;

    svg {
      margin-right: 8px;
      color: #007bff;
    }
  }
`;

const ContactLink = styled.span`
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: #0056b3;
  }
`;

const ViewDetailsButton = styled.button`
  margin-top: 15px;
  padding: 10px 25px;
  font-size: 0.9em;
  color: black;
  background-color: #ffffff;
  border: black 1px solid;
  border-radius: 20px;
  cursor: pointer;
  display: block;
  width: 100%;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;

  &:hover {
    background-color: #0056b3;
    color: #fff;
  }
`;

export default CourseAustralia;
