

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faDollarSign,
  faEarth,
//   faFileAlt,
  faMapMarkerAlt,
  faStar,
  faBook,
} from "@fortawesome/free-solid-svg-icons";

// Sample data (replace this with your actual data)
const courses = [
  {
    title: "Business and Management",
    university: "American University Washington",
    location: " Washington, D.C. ",
    worldRanking: "88",
    degree: "Certification",
    intakeDate: " Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: "American University Washington",
    location: " Washington, D.C. ",
    worldRanking: "88",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Engineering",
    university: "American University Washington",
    location: " Washington, D.C. ",
    worldRanking: "88",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Life Sciences",
    university: "American University Washington",
    location: " Washington, D.C. ",
    worldRanking: "88",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Medical and Healthcare",
    university: "American University Washington",
    location: " Washington, D.C. ",
    worldRanking: "88",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Arts",
    university: "American University Washington",
    location: " Washington, D.C. ",
    worldRanking: "88",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Law",
    university: "American University Washington",
    location: " Washington, D.C. ",
    worldRanking: "88",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: "Arizona State University",
    location: " Tempe, Arizona ",
    worldRanking: "294",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Business and Management",
    university: "Arizona State University",
    location: " Tempe, Arizona ",
    worldRanking: "294",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Engineering",
    university: "Arizona State University",
    location: " Tempe, Arizona ",
    worldRanking: "294",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Life Sciences",
    university: "Arizona State University",
    location: " Tempe, Arizona ",
    worldRanking: "294",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Medical and Healthcare",
    university: "Arizona State University",
    location: " Tempe, Arizona ",
    worldRanking: "294",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Arts",
    university: "Arizona State University",
    location: " Tempe, Arizona ",
    worldRanking: "294",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Law",
    university: "Arizona State University",
    location: " Tempe, Arizona ",
    worldRanking: "294",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: "Arkansas State University",
    location: " Jonesboro, Arkansas ",
    worldRanking: "",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Business and Management",
    university: "Arkansas State University",
    location: " Jonesboro, Arkansas ",
    worldRanking: "",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Engineering",
    university: "Arkansas State University",
    location: " Jonesboro, Arkansas ",
    worldRanking: "",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Life Sciences",
    university: "Arkansas State University",
    location: " Jonesboro, Arkansas ",
    worldRanking: "",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Medical and Healthcare ",
    university: "Arkansas State University",
    location: " Jonesboro, Arkansas ",
    worldRanking: "",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Arts",
    university: "Arkansas State University",
    location: " Jonesboro, Arkansas ",
    worldRanking: "",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Law",
    university: "Arkansas State University",
    location: " Jonesboro, Arkansas ",
    worldRanking: "",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: "Avila University (AU)",
    location: " Kansas City, Missouri ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Business and Management",
    university: "Avila University (AU)",
    location: " Kansas City, Missouri ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Engineering",
    university: "Avila University (AU)",
    location: " Kansas City, Missouri ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Life Sciences",
    university: "Avila University (AU)",
    location: " Kansas City, Missouri ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Medical and Healthcare ",
    university: "Avila University (AU)",
    location: " Kansas City, Missouri ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Arts",
    university: "Avila University (AU)",
    location: " Kansas City, Missouri ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Law",
    university: "Avila University (AU)",
    location: " Kansas City, Missouri ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: "Caldwell University",
    location: " Caldwell, New Jersey ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Business and Management",
    university: "Caldwell University",
    location: " Caldwell, New Jersey ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Engineering",
    university: "Caldwell University",
    location: " Caldwell, New Jersey ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Life Sciences",
    university: "Caldwell University",
    location: " Caldwell, New Jersey ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Medical and Healthcare",
    university: "Caldwell University",
    location: " Caldwell, New Jersey ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Arts",
    university: "Caldwell University",
    location: " Caldwell, New Jersey ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Law",
    university: "Caldwell University",
    location: " Caldwell, New Jersey ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: "California State University, Bakersfield",
    location: " Bakersfield, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Business and Management",
    university: "California State University, Bakersfield",
    location: " Bakersfield, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Engineering",
    university: "California State University, Bakersfield",
    location: " Bakersfield, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Life Sciences",
    university: "California State University, Bakersfield",
    location: " Bakersfield, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Medical and Healthcare ",
    university: "California State University, Bakersfield",
    location: " Bakersfield, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Arts",
    university: "California State University, Bakersfield",
    location: " Bakersfield, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Law",
    university: "California State University, Bakersfield",
    location: " Bakersfield, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: "California State University, Chico",
    location: " Chico, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Business and Management",
    university: "California State University, Chico",
    location: " Chico, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Engineering",
    university: "California State University, Chico",
    location: " Chico, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Life Sciences",
    university: "California State University, Chico",
    location: " Chico, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Medical and Healthcare ",
    university: "California State University, Chico",
    location: " Chico, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Arts",
    university: "California State University, Chico",
    location: " Chico, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Law",
    university: "California State University, Chico",
    location: " Chico, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: "California State University, Long Beach",
    location: " Long Beach, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Business and Management",
    university: "California State University, Long Beach",
    location: " Long Beach, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Engineering",
    university: "California State University, Long Beach",
    location: " Long Beach, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Life Sciences",
    university: "California State University, Long Beach",
    location: " Long Beach, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Medical and Healthcare ",
    university: "California State University, Long Beach",
    location: " Long Beach, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Arts",
    university: "California State University, Long Beach",
    location: " Long Beach, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Law",
    university: "California State University, Long Beach",
    location: " Long Beach, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: "California State University - Northridge",
    location: " Northridge, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Business and Management",
    university: "California State University - Northridge",
    location: " Northridge, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Engineering",
    university: "California State University - Northridge",
    location: " Northridge, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Life Sciences",
    university: "California State University - Northridge",
    location: " Northridge, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Medical and Healthcare ",
    university: "California State University - Northridge",
    location: " Northridge, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Arts",
    university: "California State University - Northridge",
    location: " Northridge, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Law",
    university: "California State University - Northridge",
    location: " Northridge, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: "California State University – Sacramento",
    location: " Sacramento, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Business and Management",
    university: "California State University – Sacramento",
    location: " Sacramento, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Business and Management",
    university: "California State University – Sacramento",
    location: " Sacramento, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Life Sciences",
    university: "California State University – Sacramento",
    location: " Sacramento, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Life Sciences",
    university: "California State University – Sacramento",
    location: " Sacramento, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Arts",
    university: "California State University – Sacramento",
    location: " Sacramento, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Law",
    university: "California State University – Sacramento",
    location: " Sacramento, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: "California State University, San Bernardino",
    location: " San Bernardino, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Business and Management",
    university: "California State University, San Bernardino",
    location: " San Bernardino, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Engineering",
    university: "California State University, San Bernardino",
    location: " San Bernardino, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Life Sciences",
    university: "California State University, San Bernardino",
    location: " San Bernardino, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Medical and Healthcare",
    university: "California State University, San Bernardino",
    location: " San Bernardino, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Arts",
    university: "California State University, San Bernardino",
    location: " San Bernardino, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Law",
    university: "California State University, San Bernardino",
    location: " San Bernardino, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: "Clark University",
    location: " Worcester, Massachusetts ",
    worldRanking: "230",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Business and Management",
    university: "Clark University",
    location: " Worcester, Massachusetts ",
    worldRanking: "230",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Engineering",
    university: "Clark University",
    location: " Worcester, Massachusetts ",
    worldRanking: "230",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Life Sciences",
    university: "Clark University",
    location: " Worcester, Massachusetts ",
    worldRanking: "230",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Medical and Healthcare ",
    university: "Clark University",
    location: " Worcester, Massachusetts ",
    worldRanking: "230",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Arts",
    university: "Clark University",
    location: " Worcester, Massachusetts ",
    worldRanking: "230",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Law",
    university: "Clark University",
    location: " Worcester, Massachusetts ",
    worldRanking: "230",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: "Cleveland State University",
    location: " Cleveland, Ohio ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Business and Management",
    university: "Cleveland State University",
    location: " Cleveland, Ohio ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Engineering",
    university: "Cleveland State University",
    location: " Cleveland, Ohio ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Life Sciences",
    university: "Cleveland State University",
    location: " Cleveland, Ohio ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Medical and Healthcare ",
    university: "Cleveland State University",
    location: " Cleveland, Ohio ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Arts",
    university: "Cleveland State University",
    location: " Cleveland, Ohio ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Law",
    university: "Cleveland State University",
    location: " Cleveland, Ohio ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: "Colorado Mesa University, Colorado",
    location: " Grand Junction, Colorado ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Business and Management",
    university: "Colorado Mesa University, Colorado",
    location: " Grand Junction, Colorado ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Engineering", 
    university: "Colorado Mesa University, Colorado",
    location: " Grand Junction, Colorado ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Life Sciences",
    university: "Colorado Mesa University, Colorado",
    location: " Grand Junction, Colorado ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Medical and Healthcare ",
    university: "Colorado Mesa University, Colorado",
    location: " Grand Junction, Colorado ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Arts",
    university: "Colorado Mesa University, Colorado",
    location: " Grand Junction, Colorado ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Law",
    university: "Colorado Mesa University, Colorado",
    location: " Grand Junction, Colorado ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: "Fairleigh Dickinson University - Florham",
    location: " Madison, New Jersey ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Business and Management",
    university: "Fairleigh Dickinson University - Florham",
    location: " Madison, New Jersey ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Engineering",
    university: "Fairleigh Dickinson University - Florham",
    location: " Madison, New Jersey ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Life Sciences",
    university: "Fairleigh Dickinson University - Florham",
    location: " Madison, New Jersey ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Medical and Healthcare ",
    university: "Fairleigh Dickinson University - Florham",
    location: " Madison, New Jersey ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Arts",
    university: "Fairleigh Dickinson University - Florham",
    location: " Madison, New Jersey ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Law",
    university: "Fairleigh Dickinson University - Florham",
    location: " Madison, New Jersey ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: "Fairleigh Dickinson University - Metropolitan",
    location: " Teaneck, New Jersey ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Business and Management",
    university: "Fairleigh Dickinson University - Metropolitan",
    location: " Teaneck, New Jersey ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Engineering",
    university: "Fairleigh Dickinson University - Metropolitan",
    location: " Teaneck, New Jersey ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Life Sciences",
    university: "Fairleigh Dickinson University - Metropolitan",
    location: " Teaneck, New Jersey ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Medical and Healthcare",
    university: "Fairleigh Dickinson University - Metropolitan",
    location: " Teaneck, New Jersey ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Arts",
    university: "Fairleigh Dickinson University - Metropolitan",
    location: " Teaneck, New Jersey ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Law",
    university: "Fairleigh Dickinson University - Metropolitan",
    location: " Teaneck, New Jersey ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: "Florida Atlantic University",
    location: " Boca Raton, Florida ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Business and Management",
    university: "Florida Atlantic University",
    location: " Boca Raton, Florida ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Engineering",
    university: "Florida Atlantic University",
    location: " Boca Raton, Florida ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Life Sciences",
    university: "Florida Atlantic University",
    location: " Boca Raton, Florida ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Medical and Healthcare ",
    university: "Florida Atlantic University",
    location: " Boca Raton, Florida ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Arts",
    university: "Florida Atlantic University",
    location: " Boca Raton, Florida ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Law",
    university: "Florida Atlantic University",
    location: " Boca Raton, Florida ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: "Gannon University",
    location: " Erie, Pennsylvania ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Business and Management",
    university: "Gannon University",
    location: " Erie, Pennsylvania ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Engineering",
    university: "Gannon University",
    location: " Erie, Pennsylvania ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Life Sciences",
    university: "Gannon University",
    location: " Erie, Pennsylvania ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Medical and Healthcare",
    university: "Gannon University",
    location: " Erie, Pennsylvania ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Arts",
    university: "Gannon University",
    location: " Erie, Pennsylvania ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Law",
    university: "Gannon University",
    location: " Erie, Pennsylvania ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: "George Mason University",
    location: "Ottawa, Canada",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Business and Management",
    university: "George Mason University",
    location: " Allendale, Michigan ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Engineering",
    university: "George Mason University",
    location: " Allendale, Michigan ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Life Sciences",
    university: "George Mason University",
    location: " Allendale, Michigan ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Medical and Healthcare",
    university: "George Mason University",
    location: " Allendale, Michigan ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Arts",
    university: "George Mason University",
    location: " Allendale, Michigan ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Law",
    university: "George Mason University",
    location: " Allendale, Michigan ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: " Grand Valley State University ",
    location: " Allendale, Michigan ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Business and Management ",
    university: " Grand Valley State University ",
    location: " Allendale, Michigan ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Engineering ",
    university: " Grand Valley State University ",
    location: " Allendale, Michigan ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Life Sciences ",
    university: " Grand Valley State University ",
    location: " Allendale, Michigan ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Medical and Healthcare ",
    university: " Grand Valley State University ",
    location: " Allendale, Michigan ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Arts ",
    university: " Grand Valley State University ",
    location: " Allendale, Michigan ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Law ",
    university: " Grand Valley State University ",
    location: " Allendale, Michigan ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: " Illinois Institute of Technology ",
    location: " Chicago, Illinois ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Business and Management ",
    university: " Illinois Institute of Technology ",
    location: " Chicago, Illinois ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Engineering ",
    university: " Illinois Institute of Technology ",
    location: " Chicago, Illinois ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Life Sciences ",
    university: " Illinois Institute of Technology ",
    location: " Chicago, Illinois ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Medical and Healthcare ",
    university: " Illinois Institute of Technology ",
    location: " Chicago, Illinois ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Arts ",
    university: " Illinois Institute of Technology ",
    location: " Chicago, Illinois ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Law ",
    university: " Illinois Institute of Technology ",
    location: " Chicago, Illinois ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: " Indiana University of Pennsylvania ",
    location: " Indiana, Pennsylvania ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Business and Management ",
    university: " Indiana University of Pennsylvania ",
    location: " Indiana, Pennsylvania ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Engineering ",
    university: " Indiana University of Pennsylvania ",
    location: " Indiana, Pennsylvania ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Life Sciences ",
    university: " Indiana University of Pennsylvania ",
    location: " Indiana, Pennsylvania ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Medical and Healthcare ",
    university: " Indiana University of Pennsylvania ",
    location: " Indiana, Pennsylvania ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Arts ",
    university: " Indiana University of Pennsylvania ",
    location: " Indiana, Pennsylvania ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Law ",
    university: " Indiana University of Pennsylvania ",
    location: " Indiana, Pennsylvania",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: " Iowa State University ",
    location: " Ames, Iowa ",
    worldRanking: "226",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Business and Management",
    university: " Iowa State University ",
    location: " Ames, Iowa ",
    worldRanking: "226",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Engineering ",
    university: " Iowa State University ",
    location: " Ames, Iowa",
    worldRanking: "226",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Life Sciences ",
    university: " Iowa State University ",
    location: " Ames, Iowa ",
    worldRanking: "226",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Medical and Healthcare ",
    university: " Iowa State University ",
    location: " Ames, Iowa ",
    worldRanking: "226",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Arts ",
    university: " Iowa State University ",
    location: " Ames, Iowa ",
    worldRanking: "226",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Law",
    university: " Iowa State University ",
    location: " Ames, Iowa ",
    worldRanking: "226",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: " Jacksonville State University ",
    location: " Jacksonville, Alabama ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Business and Management ",
    university: " Jacksonville State University ",
    location: " Jacksonville, Alabama ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Engineering ",
    university: " Jacksonville State University ",
    location: " Jacksonville, Alabama ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Life Sciences ",
    university: " Jacksonville State University ",
    location: " Jacksonville, Alabama ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Medical and Healthcare ",
    university: " Jacksonville State University ",
    location: " Jacksonville, Alabama ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Arts ",
    university: " Jacksonville State University ",
    location: " Jacksonville, Alabama ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Law",
    university: " Jacksonville State University ",
    location: " Jacksonville, Alabama ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: " James Madison University ",
    location: " Harrisonburg, Virginia ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Business and Management ",
    university: " James Madison University ",
    location: " Harrisonburg, Virginia ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Engineering ",
    university: " James Madison University ",
    location: " Harrisonburg, Virginia ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Life Sciences ",
    university: " James Madison University ",
    location: " Harrisonburg, Virginia ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Medical and Healthcare ",
    university: " James Madison University ",
    location: " Harrisonburg, Virginia ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Arts ",
    university: " James Madison University ",
    location: " Harrisonburg, Virginia ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Law ",
    university: " James Madison University ",
    location: " Harrisonburg, Virginia ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: " Kent State University - Kent ",
    location: " Kent, Ohio ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Business and Management ",
    university: " Kent State University - Kent ",
    location: " Kent, Ohio ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Engineering ",
    university: " Kent State University - Kent ",
    location: " Kent, Ohio ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Life Sciences ",
    university: " Kent State University - Kent ",
    location: " Kent, Ohio ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Medical and Healthcare ",
    university: " Kent State University - Kent ",
    location: " Kent, Ohio ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Arts ",
    university: " Kent State University - Kent ",
    location: " Kent, Ohio ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Law ",
    university: " Kent State University - Kent ",
    location: " Kent, Ohio",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: " Lewis University – Chicago ",  
    location: "Romeoville, Illinois",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Business and Management ",
    university: " Lewis University – Chicago ",
    location: "Romeoville, Illinois",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Engineering ",
    university: " Lewis University – Chicago ",
    location: "Romeoville, Illinois",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Life Sciences ",
    university: " Lewis University – Chicago ",
    location: "Romeoville, Illinois",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Medical and Healthcare",
    university: " Lewis University – Chicago ",
    location: "Romeoville, Illinois",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Arts ",
    university: " Lewis University – Chicago ",
    location: "Romeoville, Illinois",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Law",
    university: " Lewis University – Chicago ",
    location: "Romeoville, Illinois",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: " Long Island University Brooklyn ",
    location: "Brooklyn, New York",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Business and Management",
    university: " Long Island University Brooklyn ",
    location: "Brooklyn, New York",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Engineering",
    university: " Long Island University Brooklyn ",
    location: "Brooklyn, New York",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Life Sciences",
    university: " Long Island University Brooklyn ",
    location: "Brooklyn, New York",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Medical and Healthcare",
    university: " Long Island University Brooklyn ",
    location: "Brooklyn, New York",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Arts",
    university: " Long Island University Brooklyn ",
    location: "Brooklyn, New York",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Law",
    university: " Long Island University Brooklyn ",
    location: "Brooklyn, New York",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: " Louisiana Tech University",
    location: "Ruston, Louisiana",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Business and Management",
    university: " Louisiana Tech University",
    location: "Ruston, Louisiana",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Engineering",
    university: " Louisiana Tech University",
    location: "Ruston, Louisiana",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Life Sciences",
    university: " Louisiana Tech University",
    location: "Ruston, Louisiana",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Medical and Healthcare ",
    university: " Louisiana Tech University",
    location: "Ruston, Louisiana",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Arts",
    university: " Louisiana Tech University",
    location: "Ruston, Louisiana",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Law",
    university: " Louisiana Tech University",
    location: "Ruston, Louisiana",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: " Manhattanville University",
    location: "Purchase, New York",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Business and Management",
    university: " Manhattanville University",
    location: "Purchase, New York",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Engineering",
    university: " Manhattanville University",
    location: "Purchase, New York",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Life Sciences",
    university: " Manhattanville University",
    location: "Purchase, New York",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Medical and Healthcare",
    university: " Manhattanville University",
    location: "Purchase, New York",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Arts",
    university: " Manhattanville University",
    location: "Purchase, New York",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Law",
    university: " Manhattanville University",
    location: "Purchase, New York",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: " Midwestern State University (MSU Texas) ",
    location: "Wichita Falls, Texas",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Business and Management",
    university: " Midwestern State University (MSU Texas) ",
    location: "Wichita Falls, Texas",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Engineering",
    university: " Midwestern State University (MSU Texas) ",
    location: "Wichita Falls, Texas",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Life Sciences",
    university: " Midwestern State University (MSU Texas) ",
    location: "Wichita Falls, Texas",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Medical and Healthcare ",
    university: " Midwestern State University (MSU Texas) ",
    location: "Wichita Falls, Texas",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Arts",
    university: " Midwestern State University (MSU Texas) ",
    location: "Wichita Falls, Texas",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Law",
    university: " Midwestern State University (MSU Texas) ",
    location: "Wichita Falls, Texas",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: " Middle Tennessee State University",
    location: "Murfreesboro, Tennessee",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Business and Management",
    university: " Middle Tennessee State University",
    location: "Murfreesboro, Tennessee",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Engineering",
    university: " Middle Tennessee State University",
    location: "Murfreesboro, Tennessee",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Life Sciences",
    university: " Middle Tennessee State University",
    location: "Murfreesboro, Tennessee",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Medical and Healthcare ",
    university: " Middle Tennessee State University ",
    location: "Murfreesboro, Tennessee",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Arts",
    university: " Middle Tennessee State University ",
    location: "Murfreesboro, Tennessee",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Law",
    university: " Middle Tennessee State University",
    location: "Murfreesboro, Tennessee",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: " Minnesota State University",
    location: "Mankato, Minnesota",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Business and Management",
    university: " Minnesota State University",
    location: "Mankato, Minnesota",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Engineering",
    university: " Minnesota State University",
    location: "Mankato, Minnesota",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Life Sciences",
    university: " Minnesota State University",
    location: "Mankato, Minnesota",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Medical and Healthcare",
    university: " Minnesota State University",
    location: "Mankato, Minnesota",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Arts",
    university: " Minnesota State University",
    location: "Mankato, Minnesota",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Law",
    university: " Minnesota State University",
    location: "Mankato, Minnesota",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: " Mississippi State University",
    location: "Mississippi State, Mississippi",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Business and Management",
    university: " Mississippi State University",
    location: "Mississippi State, Mississippi",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Engineering",
    university: " Mississippi State University",
    location: "Mississippi State, Mississippi",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Life Sciences",
    university: " Mississippi State University",
    location: "Mississippi State, Mississippi",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Medical and Healthcare",
    university: " Mississippi State University",
    location: "Mississippi State, Mississippi",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Arts",
    university: " Mississippi State University",
    location: "Mississippi State, Mississippi",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Law",
    university: " Mississippi State University",
    location: "Mississippi State, Mississippi",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: " Missouri University of Science and Technology",
    location: "Rolla, Missouri",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Business and Management",
    university: " Missouri University of Science and Technology",
    location: "Rolla, Missouri",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Engineering",
    university: " Missouri University of Science and Technology",
    location: "Rolla, Missouri",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Life Sciences",
    university: " Missouri University of Science and Technology",
    location: "Rolla, Missouri",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Medical and Healthcare ",
    university: " Missouri University of Science and Technology",
    location: "Rolla, Missouri",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Arts",
    university: " Missouri University of Science and Technology",
    location: "Rolla, Missouri",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Law",
    university: " Missouri University of Science and Technology",
    location: "Rolla, Missouri",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: " Montana State University",
    location: "Bozeman, Montana",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Business and Management",
    university: " Montana State University",
    location: "Bozeman, Montana",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Engineering",
    university: " Montana State University",
    location: "Bozeman, Montana",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Life Sciences",
    university: " Montana State University",
    location: "Bozeman, Montana",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Medical and Healthcare",
    university: " Montana State University",
    location: "Bozeman, Montana",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Arts",
    university: " Montana State University",
    location: "Bozeman, Montana",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Law",
    university: " Montana State University",
    location: "Bozeman, Montana",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: " Morehead State University",
    location: "Morehead, Kentucky",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Business and Management",
    university: " Morehead State University",
    location: "Morehead, Kentucky",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Engineering",
    university: " Morehead State University",
    location: "Morehead, Kentucky",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Life Sciences",
    university: " Morehead State University",
    location: "Morehead, Kentucky",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Medical and Healthcare ",
    university: " Morehead State University",
    location: "Morehead, Kentucky",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Arts",
    university: " Morehead State University",
    location: "Morehead, Kentucky",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Law",
    university: " Morehead State University",
    location: "Morehead, Kentucky",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: " New Jersey Institute of Technology",
    location: " Newark, New Jersey",
    worldRanking: "560",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Business and Management",
    university: " New Jersey Institute of Technology",
    location: " Newark, New Jersey",
    worldRanking: "560",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Engineering",
    university: " New Jersey Institute of Technology",
    location: " Newark, New Jersey",
    worldRanking: "560",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Life Sciences",
    university: " New Jersey Institute of Technology",
    location: " Newark, New Jersey",
    worldRanking: "560",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Medical and Healthcare ",
    university: " New Jersey Institute of Technology",
    location: " Newark, New Jersey",
    worldRanking: "560",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Arts",
    university: " New Jersey Institute of Technology",
    location: " Newark, New Jersey",
    worldRanking: "560",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Law",
    university: " New Jersey Institute of Technology",
    location: " Newark, New Jersey",
    worldRanking: "560",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: " Northwest Missouri State University ",
    location: "Maryville, Missouri",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Business and Management",
    university: " Northwest Missouri State University ",
    location: "Maryville, Missouri",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Engineering ",
    university: " Northwest Missouri State University ",
    location: "Maryville, Missouri",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Life Sciences ",
    university: " Northwest Missouri State University ",
    location: "Maryville, Missouri",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Medical and Healthcare ",
    university: " Northwest Missouri State University ",
    location: "Maryville, Missouri",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Arts ",
    university: " Northwest Missouri State University ",
    location: "Maryville, Missouri",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Law",
    university: " Northwest Missouri State University ",
    location: "Maryville, Missouri",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: " Northeastern Illinois University ",
    location: "Chicago, Illinois",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Business and Management",
    university: " Northeastern Illinois University ",
    location: "Chicago, Illinois",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Engineering ",
    university: " Northeastern Illinois University ",
    location: "Chicago, Illinois",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Life Sciences ",
    university: " Northeastern Illinois University ",
    location: "Chicago, Illinois",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Medical and Healthcare ",
    university: " Northeastern Illinois University ",
    location: "Chicago, Illinois",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Arts ",
    university: " Northeastern Illinois University ",
    location: "Chicago, Illinois",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Law ",
    university: " Northeastern Illinois University ",
    location: "Chicago, Illinois",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: " Ohio University ",
    location: "Athens, Ohio",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Business and Management ",
    university: " Ohio University ",
    location: "Athens, Ohio",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Engineering ",
    university: " Ohio University ",
    location: "Athens, Ohio",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Life Sciences ",
    university: " Ohio University ",
    location: "Athens, Ohio",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Medical and Healthcare ",
    university: " Ohio University ",
    location: "Athens, Ohio",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Arts ",
    university: " Ohio University ",
    location: "Athens, Ohio",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Law ",
    university: " Ohio University ",
    location: "Athens, Ohio",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: " Oklahoma City University ",
    location: "Oklahoma City, Oklahoma",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Business and Management ",
    university: " Oklahoma City University ",
    location: "Oklahoma City, Oklahoma",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Engineering ",
    university: " Oklahoma City University ",
    location: "Oklahoma City, Oklahoma",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Life Sciences ",
    university: " Oklahoma City University ",
    location: "Oklahoma City, Oklahoma",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Medical and Healthcare",
    university: " Oklahoma City University ",
    location: "Oklahoma City, Oklahoma",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Arts",
    university: " Oklahoma City University ",
    location: "Oklahoma City, Oklahoma",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Law ",
    university: " Oklahoma City University ",
    location: "Oklahoma City, Oklahoma",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: " Oklahoma State University ",
    location: "Stillwater, Oklahoma",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Business and Management ",
    university: " Oklahoma State University ",
    location: "Stillwater, Oklahoma",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Engineering ",
    university: " Oklahoma State University ",
    location: "Stillwater, Oklahoma",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Life Sciences ",
    university: " Oklahoma State University ",
    location: "Stillwater, Oklahoma",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Medical and Healthcare ",
    university: " Oklahoma State University ",
    location: "Stillwater, Oklahoma",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Arts ",
    university: " Oklahoma State University ",
    location: "Stillwater, Oklahoma",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Law",
    university: " Oklahoma State University",
    location: "Stillwater, Oklahoma",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: " Oregon State University ",
    location: "Corvallis, Oregon",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Business and Management ",
    university: " Oregon State University ",
    location: "Corvallis, Oregon",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Engineering ",
    university: " Oregon State University ",
    location: "Corvallis, Oregon",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Life Sciences ",
    university: " Oregon State University ",
    location: "Corvallis, Oregon",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Medical and Healthcare ",
    university: " Oregon State University ",
    location: "Corvallis, Oregon",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Arts ",
    university: " Oregon State University ",
    location: "Corvallis, Oregon",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Law",
    university: " Oregon State University ",
    location: "Corvallis, Oregon",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: " Pittsburg State University",
    location: "Pittsburg, Kansas",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Business and Management ",
    university: " Pittsburg State University ",
    location: "Pittsburg, Kansas",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Engineering ",
    university: " Pittsburg State University ",
    location: "Pittsburg, Kansas",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Life Sciences ",
    university: " Pittsburg State University ",
    location: "Pittsburg, Kansas",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Medical and Healthcare ",
    university: " Pittsburg State University ",
    location: "Pittsburg, Kansas",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Arts ",
    university: " Pittsburg State University ",
    location: "Pittsburg, Kansas",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Law ",
    university: " Pittsburg State University ",
    location: "Pittsburg, Kansas",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: " Purdue University ",
    location: "West Lafayette, Indiana",
    worldRanking: "109",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Business and Management ",
    university: " Purdue University ",
    location: "West Lafayette, Indiana",
    worldRanking: "109",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Engineering ",
    university: " Purdue University ",
    location: "West Lafayette, Indiana",
    worldRanking: "109",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Life Sciences ",
    university: " Purdue University ",
    location: "West Lafayette, Indiana",
    worldRanking: "109",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Medical and Healthcare ",
    university: " Purdue University ",
    location: "West Lafayette, Indiana",
    worldRanking: "109",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Arts ",
    university: " Purdue University ",
    location: "West Lafayette, Indiana",
    worldRanking: "109",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Law ",
    university: " Purdue University ",
    location: "West Lafayette, Indiana",
    worldRanking: "109",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: " Purdue University Northwest ",
    location: "Hammond, Indiana",
    worldRanking: "109",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Business and Management",
    university: " Purdue University Northwest ",
    location: "Hammond, Indiana",
    worldRanking: "109",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Engineering ",
    university: " Purdue University Northwest ",
    location: "Hammond, Indiana",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Life Sciences ",
    university: " Purdue University Northwest ",
    location: "Hammond, Indiana",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Medical and Healthcare ",
    university: " Purdue University Northwest ",
    location: "Hammond, Indiana",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Arts ",
    university: " Purdue University Northwest ",
    location: " Hammond, Indiana",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Law",
    university: " Purdue University Northwest ",
    location: " Hammond, Indiana ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: " Rochester Institute of Technology ",
    location: " Rochester, New York ",
    worldRanking: "800",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Business and Management ",
    university: " Rochester Institute of Technology ",
    location: " Rochester, New York ",
    worldRanking: "800",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Engineering ",
    university: " Rochester Institute of Technology ",
    location: " Rochester, New York ",
    worldRanking: "800",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Life Sciences ",
    university: " Rochester Institute of Technology ",
    location: " Rochester, New York ",
    worldRanking: "800",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Medical and Healthcare ",
    university: " Rochester Institute of Technology ",
    location: " Rochester, New York ",
    worldRanking: "800",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Arts ",
    university: " Rochester Institute of Technology ",
    location: " Rochester, New York ",
    worldRanking: "800",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Law ",
    university: " Rochester Institute of Technology ",
    location: " Rochester, New York ",
    worldRanking: "800",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: " San Francisco State University ",
    location: " San Francisco, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Business and Management ",
    university: " San Francisco State University ",
    location: " San Francisco, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Engineering ",
    university: " San Francisco State University ",
    location: " San Francisco, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Life Sciences ",
    university: " San Francisco State University ",
    location: " San Francisco, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Medical and Healthcare ",
    university: " San Francisco State University ",
    location: " San Francisco, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Arts ",
    university: " San Francisco State University ",
    location: " San Francisco, California",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Law",
    university: " San Francisco State University ",
    location: " San Francisco, California",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: " Saginaw Valley State University ",
    location: " University Center, Michigan ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Business and Management ",
    university: " Saginaw Valley State University ",
    location: " University Center, Michigan ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Engineering",
    university: " Saginaw Valley State University ",
    location: " University Center, Michigan ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Life Sciences ",
    university: " Saginaw Valley State University ",
    location: " University Center, Michigan ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Medical and Healthcare ",
    university: " Saginaw Valley State University ",
    location: " University Center, Michigan ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Arts ",
    university: " Saginaw Valley State University ",
    location: " University Center, Michigan ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Law ",
    university: " Saginaw Valley State University ",
    location: " University Center, Michigan ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: " Saint Louis University ",
    location: " St. Louis, Missouri ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Business and Management ",
    university: " Saint Louis University ",
    location: " St. Louis, Missouri ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Engineering ",
    university: " Saint Louis University ",
    location: " St. Louis, Missouri ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Life Sciences ",
    university: " Saint Louis University ",
    location: " St. Louis, Missouri",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Medical and Healthcare",
    university: " Saint Louis University ",
    location: " St. Louis, Missouri ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Arts ",
    university: " Saint Louis University ",
    location: " St. Louis, Missouri ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Law ",
    university: " Saint Louis University",
    location: " St. Louis, Missouri ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: " Seattle University ",
    location: " Seattle, Washington ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Business and Management ",
    university: " Seattle University ",
    location: " Seattle, Washington ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Engineering ",
    university: " Seattle University ",
    location: " Seattle, Washington ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Life Sciences ",
    university: " Seattle University ",
    location: " Seattle, Washington ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Medical and Healthcare ",
    university: " Seattle University ",
    location: " Seattle, Washington ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Arts ",
    university: " Seattle University ",
    location: " Seattle, Washington ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Law ",
    university: " Seattle University ",
    location: " Seattle, Washington ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: " Sonoma State University ",
    location: " Rohnert Park, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Business and Management ",
    university: " Sonoma State University ",
    location: " Rohnert Park, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Engineering ",
    university: " Sonoma State University ",
    location: " Rohnert Park, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Life Sciences ",
    university: " Sonoma State University ",
    location: " Rohnert Park, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Medical and Healthcare ",
    university: " Sonoma State University ",
    location: " Rohnert Park, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Arts ",
    university: " Sonoma State University ",
    location: " Rohnert Park, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Law",
    university: " Sonoma State University",
    location: " Rohnert Park, California",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: " St. Cloud State University ",
    location: " St. Cloud, Minnesota ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Business and Management ",
    university: " St. Cloud State University ",
    location: " St. Cloud, Minnesota ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Engineering ",
    university: " St. Cloud State University ",
    location: " St. Cloud, Minnesota ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Life Sciences ",
    university: " St. Cloud State University ",
    location: " St. Cloud, Minnesota ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Medical and Healthcare ",
    university: " St. Cloud State University ",
    location: " St. Cloud, Minnesota ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Arts ",
    university: " St. Cloud State University ",
    location: " St. Cloud, Minnesota ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Law",
    university: " St. Cloud State University ",
    location: " St. Cloud, Minnesota ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: " Southern Connecticut State University ",
    location: " New Haven, Connecticut ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Business and Management ",
    university: " Southern Connecticut State University ",
    location: " New Haven, Connecticut ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Engineering ",
    university: " Southern Connecticut State University ",
    location: " New Haven, Connecticut ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Life Sciences ",
    university: " Southern Connecticut State University ",
    location: " New Haven, Connecticut ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Medical and Healthcare",
    university: " Southern Connecticut State University ",
    location: " New Haven, Connecticut ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Arts ",
    university: " Southern Connecticut State University ",
    location: " New Haven, Connecticut ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Law",
    university: " Southern Connecticut State University ",
    location: " New Haven, Connecticut ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: " Southeast Missouri State University ",
    location: " Cape Girardeau, Missouri ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Business and Management ",
    university: " Southeast Missouri State University ",
    location: " Cape Girardeau, Missouri ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Engineering ",
    university: " Southeast Missouri State University ",
    location: " Cape Girardeau, Missouri ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Life Sciences ",
    university: " Southeast Missouri State University ",
    location: " Cape Girardeau, Missouri ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Medical and Healthcare ",
    university: " Southeast Missouri State University ",
    location: " Cape Girardeau, Missouri ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Arts ",
    university: " Southeast Missouri State University ",
    location: " Cape Girardeau, Missouri ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Law ",
    university: " Southeast Missouri State University ",
    location: " Cape Girardeau, Missouri ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: " Southwest Minnesota State University ",
    location: " Marshall, Minnesota ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Business and Management ",
    university: " Southwest Minnesota State University ",
    location: " Marshall, Minnesota ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Engineering ",
    university: " Southwest Minnesota State University ",
    location: " Marshall, Minnesota ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Life Sciences ",
    university: " Southwest Minnesota State University ",
    location: " Marshall, Minnesota ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Medical and Healthcare",
    university: " Southwest Minnesota State University ",
    location: " Marshall, Minnesota ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Arts ",
    university: " Southwest Minnesota State University ",
    location: " Marshall, Minnesota ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Law",
    university: " Southwest Minnesota State University ",
    location: " Marshall, Minnesota ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: " South Dakota State University - Brookings ",
    location: " Brookings, South Dakota ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Business and Management",
    university: " South Dakota State University - Brookings ",
    location: " Brookings, South Dakota ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Engineering ",
    university: " South Dakota State University - Brookings ",
    location: " Brookings, South Dakota ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Life Sciences ",
    university: " South Dakota State University - Brookings ",
    location: " Brookings, South Dakota ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Medical and Healthcare ",
    university: " South Dakota State University - Brookings ",
    location: " Brookings, South Dakota ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Arts ",
    university: " South Dakota State University - Brookings ",
    location: " Brookings, South Dakota ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Law",
    university: " South Dakota State University - Brookings ",
    location: " Brookings, South Dakota ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: " Southern New Hampshire University ",
    location: " Manchester, New Hampshire ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Business and Management ",
    university: " Southern New Hampshire University ",
    location: " Manchester, New Hampshire ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Engineering ",
    university: " Southern New Hampshire University ",
    location: " Manchester, New Hampshire ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Life Sciences ",
    university: " Southern New Hampshire University ",
    location: " Manchester, New Hampshire ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Medical and Healthcare ",
    university: " Southern New Hampshire University ",
    location: " Manchester, New Hampshire ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Arts ",
    university: " Southern New Hampshire University ",
    location: " Manchester, New Hampshire ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Law",
    university: " Southern New Hampshire University",
    location: " Manchester, New Hampshire ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: " State University of New York (SUNY) at Oswego ",
    location: " Oswego, New York ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Business and Management ",
    university: " State University of New York (SUNY) at Oswego ",
    location: " Oswego, New York ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Engineering ",
    university: " State University of New York (SUNY) at Oswego ",
    location: " Oswego, New York ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Life Sciences ",
    university: " State University of New York (SUNY) at Oswego ",
    location: " Oswego, New York ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Medical and Healthcare ",
    university: " State University of New York (SUNY) at Oswego ",
    location: " Oswego, New York ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Arts ",
    university: " State University of New York (SUNY) at Oswego ",
    location: " Oswego, New York ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Law ",
    university: " State University of New York (SUNY) at Oswego ",
    location: " Oswego, New York ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: " State University of New York (SUNY) at Plattsburgh ",
    location: " Plattsburgh, New York ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Business and Management ",
    university: " State University of New York (SUNY) at Plattsburgh ",
    location: " Plattsburgh, New York ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Engineering ",
    university: " State University of New York (SUNY) at Plattsburgh ",
    location: " Plattsburgh, New York ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Life Sciences ",
    university: " State University of New York (SUNY) at Plattsburgh ",
    location: " Plattsburgh, New York ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Medical and Healthcare ",
    university: " State University of New York (SUNY) at Plattsburgh ",
    location: " Plattsburgh, New York ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Arts ",
    university: " State University of New York (SUNY) at Plattsburgh ",
    location: " Plattsburgh, New York ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Law",
    university: " State University of New York (SUNY) at Plattsburgh",
    location: " Plattsburgh, New York ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: " Temple University ",
    location: " Philadelphia, Pennsylvania ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Business and Management ",
    university: " Temple University ",
    location: " Philadelphia, Pennsylvania ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Engineering ",
    university: " Temple University ",
    location: " Philadelphia, Pennsylvania ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Life Sciences ",
    university: " Temple University ",
    location: " Philadelphia, Pennsylvania ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Medical and Healthcare",
    university: " Temple University ",
    location: " Philadelphia, Pennsylvania ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Arts ",
    university: " Temple University ",
    location: " Philadelphia, Pennsylvania ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Law",
    university: " Temple University ",
    location: " Philadelphia, Pennsylvania ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: " Texas A&M University - Corpus Christi ",
    location: " Corpus Christi, Texas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Business and Management ",
    university: " Texas A&M University - Corpus Christi ",
    location: " Corpus Christi, Texas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Engineering ",
    university: " Texas A&M University - Corpus Christi ",
    location: " Corpus Christi, Texas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Life Sciences ",
    university: " Texas A&M University - Corpus Christi ",
    location: " Corpus Christi, Texas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Medical and Healthcare",
    university: " Texas A&M University - Corpus Christi ",
    location: " Corpus Christi, Texas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Arts ",
    university: " Texas A&M University - Corpus Christi ",
    location: " Corpus Christi, Texas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Law",
    university: " Texas A&M University - Corpus Christi ",
    location: " Corpus Christi, Texas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: " Texas Tech University ",
    location: " Lubbock, Texas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Business and Management ",
    university: " Texas Tech University ",
    location: " Lubbock, Texas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Engineering ",
    university: " Texas Tech University ",
    location: " Lubbock, Texas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Life Sciences ",
    university: " Texas Tech University ",
    location: " Lubbock, Texas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Medical and Healthcare ",
    university: " Texas Tech University ",
    location: " Lubbock, Texas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Arts ",
    university: " Texas Tech University ",
    location: " Lubbock, Texas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: " Law",
    university: " Texas Tech University ",
    location: " Lubbock, Texas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Science and Technology",
    university: " Texas State University - San Marcos ",
    location: " San Marcos, Texas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Business and Management",
    university: " Texas State University - San Marcos ",
    location: " San Marcos, Texas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Engineering",
    university: " Texas State University - San Marcos ",
    location: " San Marcos, Texas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Life Sciences",
    university: " Texas State University - San Marcos ",
    location: " San Marcos, Texas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Medical and Healthcare",
    university: " Texas State University - San Marcos ",
    location: " San Marcos, Texas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Arts",
    university: " Texas State University - San Marcos ",
    location: " San Marcos, Texas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Law",
    university: " Texas State University - San Marcos ",
    location: " San Marcos, Texas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Science and Technology",
    university: " Texas Wesleyan University",
    location: " Fort Worth, Texas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Business and Management",
    university: " Texas Wesleyan University",
    location: " Fort Worth, Texas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Engineering",
    university: " Texas Wesleyan University",
    location: " Fort Worth, Texas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Life Sciences",
    university: " Texas Wesleyan University",
    location: " Fort Worth, Texas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Medical and Healthcare",
    university: " Texas Wesleyan University",
    location: " Fort Worth, Texas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Arts",
    university: " Texas Wesleyan University",
    location: " Fort Worth, Texas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Law",
    university: " Texas Wesleyan University",
    location: " Fort Worth, Texas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Science and Technology",
    university: " Towson University",
    location: " Towson, Maryland ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Business and Management",
    university: " Towson University",
    location: " Towson, Maryland ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Engineering",
    university: " Towson University",
    location: " Towson, Maryland ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Life Sciences",
    university: " Towson University",
    location: " Towson, Maryland ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Medical and Healthcare ",
    university: " Towson University",
    location: " Towson, Maryland ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Arts",
    university: " Towson University",
    location: " Towson, Maryland ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Law",
    university: " Towson University",
    location: " Towson, Maryland ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Science and Technology",
    university: " Troy University - Troy",
    location: " Troy, Alabama ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Business and Management",
    university: " Troy University - Troy",
    location: " Troy, Alabama ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Engineering",
    university: " Troy University - Troy",
    location: " Troy, Alabama ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Life Sciences",
    university: " Troy University - Troy",
    location: " Troy, Alabama ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Medical and Healthcare",
    university: " Troy University - Troy",
    location: " Troy, Alabama ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Arts",
    university: " Troy University - Troy",
    location: " Troy, Alabama ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Law",
    university: " Troy University - Troy",
    location: " Troy, Alabama ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Science and Technology",
    university: " University of Akron",
    location: " Akron, Ohio ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Business and Management",
    university: " University of Akron",
    location: " Akron, Ohio ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Engineering",
    university: " University of Akron",
    location: " Akron, Ohio ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Life Sciences",
    university: " University of Akron",
    location: " Akron, Ohio ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Medical and Healthcare",
    university: " University of Akron",
    location: " Akron, Ohio ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Arts",
    university: " University of Akron",
    location: " Akron, Ohio ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Law",
    university: " University of Akron",
    location: " Akron, Ohio",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Science and Technology",
    university: " University of Alabama at Birmingham",
    location: " Birmingham, Alabama ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Business and Management",
    university: " University of Alabama at Birmingham",
    location: " Birmingham, Alabama ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Engineering",
    university: " University of Alabama at Birmingham",
    location: " Birmingham, Alabama ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Life Sciences",
    university: " University of Alabama at Birmingham",
    location: " Birmingham, Alabama ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Medical and Healthcare",
    university: " University of Alabama at Birmingham",
    location: " Birmingham, Alabama ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Arts",
    university: " University of Alabama at Birmingham",
    location: " Birmingham, Alabama ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Law",
    university: " University of Alabama at Birmingham",
    location: " Birmingham, Alabama ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Science and Technology",
    university: " University of Bridgeport",
    location: " Bridgeport, Connecticut ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Business and Management",
    university: " University of Bridgeport",
    location: " Bridgeport, Connecticut ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Engineering",
    university: " University of Bridgeport",
    location: " Bridgeport, Connecticut ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Life Sciences",
    university: " University of Bridgeport",
    location: " Bridgeport, Connecticut ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Medical and Healthcare",
    university: " University of Bridgeport",
    location: " Bridgeport, Connecticut ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Arts",
    university: " University of Bridgeport",
    location: " Bridgeport, Connecticut ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Law",
    university: " University of Bridgeport",
    location: " Bridgeport, Connecticut ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Science and Technology",
    university: " University of Central Arkansas",
    location: " Conway, Arkansas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Business and Management",
    university: " University of Central Arkansas",
    location: " Conway, Arkansas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Engineering",
    university: " University of Central Arkansas",
    location: " Conway, Arkansas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Life Sciences",
    university: " University of Central Arkansas",
    location: " Conway, Arkansas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Medical and Healthcare",
    university: " University of Central Arkansas",
    location: " Conway, Arkansas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Arts",
    university: " University of Central Arkansas",
    location: " Conway, Arkansas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Law",
    university: " University of Central Arkansas",
    location: " Conway, Arkansas",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Science and Technology",
    university: " University of Central Missouri - Warrensburg",
    location: " Warrensburg, Missouri ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Business and Management",
    university: " University of Central Missouri - Warrensburg",
    location: " Warrensburg, Missouri ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Engineering",
    university: " University of Central Missouri - Warrensburg",
    location: " Warrensburg, Missouri ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Life Sciences",
    university: " University of Central Missouri - Warrensburg",
    location: " Warrensburg, Missouri ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Medical and Healthcare ",
    university: " University of Central Missouri - Warrensburg",
    location: " Warrensburg, Missouri ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Arts",
    university: " University of Central Missouri - Warrensburg",
    location: " Warrensburg, Missouri ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Law",
    university: " University of Central Missouri - Warrensburg",
    location: " Warrensburg, Missouri ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Science and Technology",
    university: " University of Central Oklahoma",
    location: " Edmond, Oklahoma ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Business and Management",
    university: " University of Central Oklahoma",
    location: " Edmond, Oklahoma ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Engineering",
    university: " University of Central Oklahoma",
    location: " Edmond, Oklahoma ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Life Sciences",
    university: " University of Central Oklahoma",
    location: " Edmond, Oklahoma ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Medical and Healthcare",
    university: " University of Central Oklahoma",
    location: " Edmond, Oklahoma ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Arts",
    university: " University of Central Oklahoma",
    location: " Edmond, Oklahoma ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Law",
    university: " University of Central Oklahoma",
    location: "Edmond, Oklahoma",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Science and Technology",
    university: " University of California- Los Angeles Campus",
    location: " Los Angeles, California ",
    worldRanking: "15",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Business and Management",
    university: " University of California- Los Angeles Campus",
    location: " Los Angeles, California ",
    worldRanking: "15",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Engineering",
    university: " University of California- Los Angeles Campus",
    location: " Los Angeles, California ",
    worldRanking: "15",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Life Sciences",
    university: " University of California- Los Angeles Campus",
    location: " Los Angeles, California ",
    worldRanking: "15",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Medical and Healthcare",
    university: " University of California- Los Angeles Campus",
    location: " Los Angeles, California ",
    worldRanking: "15",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Arts",
    university: " University of California- Los Angeles Campus",
    location: " Los Angeles, California ",
    worldRanking: "15",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Law",
    university: " University of California- Los Angeles Campus",
    location: " Los Angeles, California ",
    worldRanking: "15",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Science and Technology",
    university: " University of Colorado – Denver",
    location: " Denver, Colorado ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Business and Management",
    university: " University of Colorado – Denver",
    location: " Denver, Colorado ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Engineering",
    university: " University of Colorado – Denver",
    location: " Denver, Colorado ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Life Sciences",
    university: " University of Colorado – Denver",
    location: " Denver, Colorado ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Medical and Healthcare",
    university: " University of Colorado – Denver",
    location: " Denver, Colorado ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Arts",
    university: " University of Colorado – Denver",
    location: " Denver, Colorado ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Law",
    university: " University of Colorado – Denver",
    location: " Denver, Colorado ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Science and Technology",
    university: " University of Delaware",
    location: " Newark, Delaware ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Business and Management",
    university: " University of Delaware",
    location: " Newark, Delaware ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Engineering",
    university: " University of Delaware",
    location: " Newark, Delaware ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Life Sciences",
    university: " University of Delaware",
    location: " Newark, Delaware ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Medical and Healthcare",
    university: " University of Delaware",
    location: " Newark, Delaware ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Arts",
    university: " University of Delaware",
    location: " Newark, Delaware ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Law",
    university: " University of Delaware",
    location: " Newark, Delaware ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Science and Technology",
    university: " University of Houston",
    location: " Houston, Texas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Business and Management",
    university: " University of Houston",
    location: " Houston, Texas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Engineering",
    university: " University of Houston",
    location: " Houston, Texas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Life Sciences",
    university: " University of Houston",
    location: " Houston, Texas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Medical and Healthcare",
    university: " University of Houston",
    location: " Houston, Texas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Arts",
    university: " University of Houston",
    location: " Houston, Texas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Law",
    university: " University of Houston",
    location: " Houston, Texas",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Science and Technology",
    university: " University of Idaho",
    location: " Moscow, Idaho ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Business and Management",
    university: " University of Idaho",
    location: " Moscow, Idaho ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Engineering",
    university: " University of Idaho",
    location: " Moscow, Idaho ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Life Sciences",
    university: " University of Idaho",
    location: " Moscow, Idaho ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Medical and Healthcare",
    university: " University of Idaho",
    location: " Moscow, Idaho ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Arts",
    university: " University of Idaho",
    location: " Moscow, Idaho ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Law",
    university: " University of Idaho",
    location: " Moscow, Idaho ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Science and Technology",
    university: " University of Indianapolis ",
    location: " Indianapolis, Indiana ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Business and Management",
    university: " University of Indianapolis ",
    location: " Indianapolis, Indiana ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Engineering",
    university: " University of Indianapolis ",
    location: " Indianapolis, Indiana ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Life Sciences",
    university: " University of Indianapolis ",
    location: " Indianapolis, Indiana ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Medical and Healthcare",
    university: " University of Indianapolis ",
    location: " Indianapolis, Indiana ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Arts",
    university: " University of Indianapolis",
    location: " Indianapolis, Indiana ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Law",
    university: " University of Indianapolis",
    location: " Indianapolis, Indiana",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Science and Technology",
    university: " University of Illinois Chicago",
    location: " Chicago, Illinois ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Business and Management",
    university: " University of Illinois Chicago ",
    location: " Chicago, Illinois ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Engineering ",
    university: " University of Illinois Chicago",
    location: " Chicago, Illinois ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Life Sciences",
    university: " University of Illinois Chicago",
    location: " Chicago, Illinois ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Medical and Healthcare",
    university: " University of Illinois Chicago ",
    location: " Chicago, Illinois ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Arts",
    university: " University of Illinois Chicago ",
    location: " Chicago, Illinois ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Law",
    university: " University of Illinois Chicago",
    location: " Chicago, Illinois ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Science and Technology",
    university: " University of Illinois Springfield (UIS)",
    location: " Springfield, Illinois ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Business and Management",
    university: " University of Illinois Springfield (UIS)",
    location: " Springfield, Illinois ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Engineering ",
    university: " University of Illinois Springfield (UIS) ",
    location: " Springfield, Illinois ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Life Sciences ",
    university: " University of Illinois Springfield (UIS) ",
    location: " Springfield, Illinois ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Medical and Healthcare ",
    university: " University of Illinois Springfield (UIS) ",
    location: " Springfield, Illinois ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Arts ",
    university: " University of Illinois Springfield (UIS) ",
    location: " Springfield, Illinois ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Law ",
    university: " University of Illinois Springfield (UIS) ",
    location: " Springfield, Illinois ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Science and Technology",
    university: " University of Kansas ",
    location: " Lawrence, Kansas ",
    worldRanking: "327",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Business and Management ",
    university: " University of Kansas ",
    location: " Lawrence, Kansas ",
    worldRanking: "327",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Engineering ",
    university: " University of Kansas ",
    location: " Lawrence, Kansas ",
    worldRanking: "327",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Life Sciences ",
    university: " University of Kansas ",
    location: " Lawrence, Kansas ",
    worldRanking: "327",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Medical and Healthcare",
    university: " University of Kansas ",
    location: " Lawrence, Kansas ",
    worldRanking: "327",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Arts",
    university: " University of Kansas ",
    location: " Lawrence, Kansas ",
    worldRanking: "327",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Law",
    university: " University of Kansas ",
    location: " Lawrence, Kansas ",
    worldRanking: "327",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Science and Technology",
    university: " University of Lynchburg ",
    location: " Lynchburg, Virginia ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Business and Management",
    university: " University of Lynchburg ",
    location: " Lynchburg, Virginia ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Engineering",
    university: " University of Lynchburg ",
    location: " Lynchburg, Virginia ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Life Sciences ",
    university: " University of Lynchburg ",
    location: " Lynchburg, Virginia ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Medical and Healthcare",
    university: " University of Lynchburg ",
    location: " Lynchburg, Virginia ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Arts ",
    university: " University of Lynchburg ",
    location: " Lynchburg, Virginia ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Law ",
    university: " University of Lynchburg",
    location: " Lynchburg, Virginia ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Science and Technology",
    university: " University of Maryland ",
    location: " College Park, Maryland ",
    worldRanking: "64",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Business and Management ",
    university: " University of Maryland ",
    location: " College Park, Maryland ",
    worldRanking: "64",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Engineering ",
    university: " University of Maryland ",
    location: " College Park, Maryland ",
    worldRanking: "64",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Life Sciences ",
    university: " University of Maryland ",
    location: " College Park, Maryland ",
    worldRanking: "64",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Medical and Healthcare",
    university: " University of Maryland ",
    location: " College Park, Maryland ",
    worldRanking: "64",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Arts ",
    university: " University of Maryland ",
    location: " College Park, Maryland ",
    worldRanking: "64",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Law",
    university: " University of Maryland",
    location: " College Park, Maryland ",
    worldRanking: "64",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Science and Technology",
    university: " University of Michigan ",
    location: " Ann Arbor, Michigan ",
    worldRanking: "23",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Business and Management",
    university: " University of Michigan ",
    location: " Ann Arbor, Michigan ",
    worldRanking: "23",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Engineering ",
    university: " University of Michigan ",
    location: " Ann Arbor, Michigan ",
    worldRanking: "23",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Life Sciences ",
    university: " University of Michigan ",
    location: " Ann Arbor, Michigan ",
    worldRanking: "23",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Medical and Healthcare",
    university: " University of Michigan ",
    location: " Ann Arbor, Michigan ",
    worldRanking: "23",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Arts ",
    university: " University of Michigan ",
    location: " Ann Arbor, Michigan ",
    worldRanking: "23",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Law",
    university: " University of Michigan ",
    location: " Ann Arbor, Michigan ",
    worldRanking: "23",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Science and Technology",
    university: " University of Massachusetts Dartmouth ",
    location: " North Dartmouth, Massachusetts ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Business and Management ",
    university: " University of Massachusetts Dartmouth ",
    location: " North Dartmouth, Massachusetts ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Engineering ",
    university: " University of Massachusetts Dartmouth ",
    location: " North Dartmouth, Massachusetts ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Life Sciences ",
    university: " University of Massachusetts Dartmouth ",
    location: " North Dartmouth, Massachusetts ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Medical and Healthcare",
    university: " University of Massachusetts Dartmouth ",
    location: " North Dartmouth, Massachusetts ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Arts ",
    university: " University of Massachusetts Dartmouth ",
    location: " North Dartmouth, Massachusetts ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Law",
    university: " University of Massachusetts Dartmouth ",
    location: " North Dartmouth, Massachusetts",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Science and Technology",
    university: " University of Massachusetts Lowell ",
    location: " Lowell, Massachusetts ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Business and Management ",
    university: " University of Massachusetts Lowell ",
    location: " Lowell, Massachusetts ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Engineering ",
    university: " University of Massachusetts Lowell ",
    location: " Lowell, Massachusetts ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Life Sciences",
    university: " University of Massachusetts Lowell ",
    location: " Lowell, Massachusetts ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Medical and Healthcare ",
    university: " University of Massachusetts Lowell ",
    location: " Lowell, Massachusetts ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Arts ",
    university: " University of Massachusetts Lowell ",
    location: " Lowell, Massachusetts ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Law ",
    university: " University of Massachusetts Lowell ",
    location: " Lowell, Massachusetts ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Science and Technology",
    university: " University of Missouri-St. Louis ",
    location: " St. Louis, Missouri ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Business and Management ",
    university: " University of Missouri-St. Louis ",
    location: " St. Louis, Missouri ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Engineering ",
    university: " University of Missouri-St. Louis ",
    location: " St. Louis, Missouri ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Life Sciences ",
    university: " University of Missouri-St. Louis ",
    location: " St. Louis, Missouri ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Medical and Healthcare ",
    university: " University of Missouri-St. Louis ",
    location: " St. Louis, Missouri ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Arts ",
    university: " University of Missouri-St. Louis ",
    location: " St. Louis, Missouri ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Law ",
    university: " University of Missouri-St. Louis ",
    location: " St. Louis, Missouri ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Science and Technology",
    university: " University of Nebraska Omaha ",
    location: " Omaha, Nebraska ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Business and Management ",
    university: " University of Nebraska Omaha ",
    location: " Omaha, Nebraska ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Engineering ",
    university: " University of Nebraska Omaha ",
    location: " Omaha, Nebraska ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Life Sciences ",
    university: " University of Nebraska Omaha ",
    location: " Omaha, Nebraska ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Medical and Healthcare ",
    university: " University of Nebraska Omaha ",
    location: " Omaha, Nebraska ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Arts ",
    university: " University of Nebraska Omaha ",
    location: " Omaha, Nebraska ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Law ",
    university: " University of Nebraska Omaha ",
    location: " Omaha, Nebraska ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Science and Technology",
    university: " University of New Haven ",
    location: " West Haven, Connecticut ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Business and Managament",
    university: " University of New Haven ",
    location: " West Haven, Connecticut ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Engineering ",
    university: " University of New Haven ",
    location: " West Haven, Connecticut ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Life Sciences ",
    university: " University of New Haven ",
    location: " West Haven, Connecticut ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Medical and Healthcare ",
    university: " University of New Haven ",
    location: " West Haven, Connecticut ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Arts ",
    university: " University of New Haven ",
    location: " West Haven, Connecticut ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Law",
    university: " University of New Haven ",
    location: " West Haven, Connecticut",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Science and Technology",
    university: " University of North Carolina ",
    location: " Chapel Hill, North Carolina ",
    worldRanking: "92",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Business and Management ",
    university: " University of North Carolina ",
    location: " Chapel Hill, North Carolina ",
    worldRanking: "92",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Engineering ",
    university: " University of North Carolina ",
    location: " Chapel Hill, North Carolina ",
    worldRanking: "92",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Life Sciences ",
    university: " University of North Carolina ",
    location: " Chapel Hill, North Carolina ",
    worldRanking: "92",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Medical and Healthcare ",
    university: " University of North Carolina ",
    location: " Chapel Hill, North Carolina ",
    worldRanking: "92",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Arts ",
    university: " University of North Carolina ",
    location: " Chapel Hill, North Carolina ",
    worldRanking: "92",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Law ",
    university: " University of North Carolina ",
    location: " Chapel Hill, North Carolina ",
    worldRanking: "92",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Science and Technology",
    university: " University of South Dakota ",
    location: " Jacksonville, Florida ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Business and Management ",
    university: " University of South Dakota ",
    location: " Jacksonville, Florida ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Engineering ",
    university: " University of South Dakota ",
    location: " Jacksonville, Florida ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Life Sciences ",
    university: " University of South Dakota ",
    location: " Jacksonville, Florida ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Medical and Healthcare ",
    university: " University of South Dakota ",
    location: " Jacksonville, Florida ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Arts ",
    university: " University of South Dakota ",
    location: " Jacksonville, Florida ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Law",
    university: " University of South Dakota ",
    location: " Jacksonville, Florida",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Science and Technology",
    university: " University of South Florida",
    location: " Jacksonville, Florida ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Business and Management ",
    university: " University of South Florida ",
    location: " Jacksonville, Florida ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Engineering ",
    university: " University of South Florida ",
    location: " Jacksonville, Florida ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Life Sciences ",
    university: " University of South Florida ",
    location: " Jacksonville, Florida ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Medical and Healthcare ",
    university: " University of South Florida ",
    location: " Jacksonville, Florida ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Arts ",
    university: " University of South Florida ",
    location: " Jacksonville, Florida ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Law ",
    university: " University of South Florida ",
    location: " Jacksonville, Florida ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Science and Technology",
    university: " University of Tampa ",
    location: " Tampa, Florida ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },
{
    title: "Business and Management",
    university: " University of Tampa ",
    location: " Tampa, Florida ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Engineering",
    university: " University of Tampa ",
    location: " Tampa, Florida ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Life Sciences",
    university: " University of Tampa ",
    location: " Tampa, Florida ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Medical and Healthcare",
    university: " University of Tampa ",
    location: " Tampa, Florida ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Arts",
    university: " University of Tampa ",
    location: " Tampa, Florida ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Law",
    university: " University of Tampa ",
    location: " Tampa, Florida ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Science and Technology",
    university: " University of Texas at Tyler",
    location: " Tyler, Texas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Business and Management",
    university: " University of Texas at Tyler",
    location: " Tyler, Texas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Engineering",
    university: " University of Texas at Tyler",
    location: " Tyler, Texas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Life Sciences",
    university: " University of Texas at Tyler",
    location: " Tyler, Texas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Medical and Healthcare",
    university: " University of Texas at Tyler",
    location: " Tyler, Texas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Arts",
    university: " University of Texas at Tyler",
    location: " Tyler, Texas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Law",
    university: " University of Texas at Tyler",
    location: " Tyler, Texas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Science and Technology",
    university: " University of Toledo",
    location: " Toledo, Ohio ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Business and Management",
    university: " University of Toledo",
    location: " Toledo, Ohio ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Engineering",
    university: " University of Toledo",
    location: " Toledo, Ohio ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Life Sciences",
    university: " University of Toledo",
    location: " Toledo, Ohio ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Medical and Healthcare",
    university: " University of Toledo",
    location: " Toledo, Ohio ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Arts",
    university: " University of Toledo",
    location: " Toledo, Ohio ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Law",
    university: " University of Toledo",
    location: " Toledo, Ohio ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Science and Technology",
    university: " University of West Florida – Pensacola, Emerald Coast",
    location: " Florida ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Business and Management ",
    university: " University of West Florida – Pensacola, Emerald Coast ",
    location: " Florida ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Engineering ",
    university: " University of West Florida – Pensacola, Emerald Coast ",
    location: " Florida ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Life Sciences ",
    university: " University of West Florida – Pensacola, Emerald Coast ",
    location: " Florida ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Medical and Healthcare ",
    university: " University of West Florida – Pensacola, Emerald Coast ",
    location: " Florida ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Arts ",
    university: " University of West Florida – Pensacola, Emerald Coast ",
    location: " Florida ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Law",
    university: " University of West Florida – Pensacola, Emerald Coast ",
    location: " Florida ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Science and Technology",
    university: " University of Wisconsin – Eau Claire, Stout, Superior",
    location: " Wisconsin ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Business and Management ",
    university: " University of Wisconsin – Eau Claire, Stout, Superior ",
    location: " Wisconsin ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Engineering ",
    university: " University of Wisconsin – Eau Claire, Stout, Superior ",
    location: " Wisconsin ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Life Sciences ",
    university: " University of Wisconsin – Eau Claire, Stout, Superior ",
    location: " Wisconsin ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Medical and Healthcare ",
    university: " University of Wisconsin – Eau Claire, Stout, Superior ",
    location: " Wisconsin ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Arts ",
    university: " University of Wisconsin – Eau Claire, Stout, Superior ",
    location: " Wisconsin ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Law ",
    university: " University of Wisconsin – Eau Claire, Stout, Superior ",
    location: " Wisconsin ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Science and Technology",
    university: " University of Wyoming ",
    location: " Laramie, Wyoming ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Business and Management ",
    university: " University of Wyoming ",
    location: " Laramie, Wyoming ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Engineering ",
    university: " University of Wyoming ",
    location: " Laramie, Wyoming ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Life Sciences ",
    university: " University of Wyoming ",
    location: " Laramie, Wyoming ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Medical and Healthcare ",
    university: " University of Wyoming ",
    location: " Laramie, Wyoming ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Arts ",
    university: " University of Wyoming ",
    location: " Laramie, Wyoming ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Law ",
    university: " University of Wyoming ",
    location: " Laramie, Wyoming ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Science and Technology",
    university: " Weber State University ",
    location: " Ogden, Utah ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Business and Management ",
    university: " Weber State University ",
    location: " Ogden, Utah ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Engineering ",
    university: " Weber State University ",
    location: " Ogden, Utah ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Life Sciences ",
    university: " Weber State University ",
    location: " Ogden, Utah ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Medical and Healthcare ",
    university: " Weber State University ",
    location: " Ogden, Utah ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Arts ",
    university: " Weber State University ",
    location: " Ogden, Utah ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Law",
    university: " Weber State University ",
    location: " Ogden, Utah ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Science and Technology",
    university: " Webster University ",
    location: " St. Louis, Missouri with campuses worldwide ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Business and Management ",
    university: " Webster University ",
    location: " St. Louis, Missouri with campuses worldwide ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Engineering ",
    university: " Webster University ",
    location: " St. Louis, Missouri with campuses worldwide ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Life Sciences ",
    university: " Webster University ",
    location: " St. Louis, Missouri with campuses worldwide ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Medical and Healthcare ",
    university: " Webster University ",
    location: " St. Louis, Missouri with campuses worldwide ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Arts ",
    university: " Webster University ",
    location: " St. Louis, Missouri with campuses worldwide ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Law ",
    university: " Webster University ",
    location: " St. Louis, Missouri with campuses worldwide ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Science and Technology",
    university: " West Virginia University ",
    location: " Morgantown, West Virginia ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Business and Management ",
    university: " West Virginia University ",
    location: " Morgantown, West Virginia ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Engineering ",
    university: " West Virginia University ",
    location: " Morgantown, West Virginia ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Life Sciences ",
    university: " West Virginia University ",
    location: " Morgantown, West Virginia ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Medical and Healthcare ",
    university: " West Virginia University ",
    location: " Morgantown, West Virginia ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Arts ",
    university: " West Virginia University ",
    location: " Morgantown, West Virginia ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Law ",
    university: " West Virginia University ",
    location: " Morgantown, West Virginia ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Science and Technology",
    university: " Westcliff University - Irvine ",
    location: " Irvine, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Business and Management",
    university: " Westcliff University - Irvine ",
    location: " Irvine, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Engineering ",
    university: " Westcliff University - Irvine ",
    location: " Irvine, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Life Sciences ",
    university: " Westcliff University - Irvine ",
    location: " Irvine, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Medical and Healthcare ",
    university: " Westcliff University - Irvine ",
    location: " Irvine, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Arts ",
    university: " Westcliff University - Irvine ",
    location: " Irvine, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Law ",
    university: " Westcliff University - Irvine ",
    location: " Irvine, California ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Science and Technology",
    university: " Western Michigan University – Kalamazoo ",
    location: " Kalamazoo, Michigan ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Business and Management ",
    university: " Western Michigan University – Kalamazoo ",
    location: " Kalamazoo, Michigan ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Engineering ",
    university: " Western Michigan University – Kalamazoo ",
    location: " Kalamazoo, Michigan ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Life Sciences ",
    university: " Western Michigan University – Kalamazoo ",
    location: " Kalamazoo, Michigan ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Medical and Healthcare ",
    university: " Western Michigan University – Kalamazoo ",
    location: " Kalamazoo, Michigan ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Arts ",
    university: " Western Michigan University – Kalamazoo ",
    location: " Kalamazoo, Michigan ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Law ",
    university: " Western Michigan University – Kalamazoo ",
    location: " Kalamazoo, Michigan",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Science and Technology",
    university: " Wichita State University ",
    location: " Wichita, Kansas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Business and Management ",
    university: " Wichita State University ",
    location: " Wichita, Kansas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Engineering ",
    university: " Wichita State University ",
    location: " Wichita, Kansas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Life Sciences ",
    university: " Wichita State University ",
    location: " Wichita, Kansas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Medical and Healthcare",
    university: " Wichita State University ",
    location: " Wichita, Kansas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Arts ",
    university: " Wichita State University ",
    location: " Wichita, Kansas ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Law",
    university: " Wichita State University ",
    location: " Wichita, Kansas",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Science and Technology",
    university: " Wright State University ",
    location: " Dayton, Ohio ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Business and Management ",
    university: " Wright State University ",
    location: " Dayton, Ohio ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Engineering ",
    university: " Wright State University ",
    location: " Dayton, Ohio ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Life Sciences ",
    university: " Wright State University ",
    location: " Dayton, Ohio ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Medical and Healthcare",
    university: " Wright State University ",
    location: " Dayton, Ohio ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Arts ",
    university: " Wright State University ",
    location: " Dayton, Ohio ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Law",
    university: " Wright State University",
    location: " Dayton, Ohio ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: "Science and Technology",
    university: " Youngstown State University ",
    location: " Youngstown, Ohio ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Business and Management ",
    university: " Youngstown State University ",
    location: " Youngstown, Ohio ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Engineering ",
    university: " Youngstown State University ",
    location: " Youngstown, Ohio ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Life Sciences ",
    university: " Youngstown State University ",
    location: " Youngstown, Ohio ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Medical and Healthcare ",
    university: " Youngstown State University ",
    location: " Youngstown, Ohio ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Arts ",
    university: " Youngstown State University ",
    location: " Youngstown, Ohio ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },

{
    title: " Law",
    university: " Youngstown State University",
    location: " Youngstown, Ohio ",
    worldRanking: "50",
    degree: "Certification",
    intakeDate: "Jan (Spring), May (Summer), August (Fall)",
    entryScore: "IELTS 6.0",
    fees: "Contact AIEC",
    link: "/bachelorofdesign",
  },


  // Add more courses as needed
];

const CourseUsa = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [filteredPrograms, setFilteredPrograms] = useState([]);

  useEffect(() => {
    const searchCriteria = location.state || {};
    const filtered = courses.filter(
      (program) =>
        (!searchCriteria.course ||
          program.title.toLowerCase().includes(searchCriteria.course?.toLowerCase()))
    );

    setFilteredPrograms(filtered.length > 0 ? filtered : null);
  }, [location.state]);

  const handleViewDetails = (course) => {
    navigate("/course-detail", {
      state: {
        courseName: course.title,
        universityName: course.university,
        countryName: course.location.trim(),
        worldRanking: course.worldRanking,
        degree: course.degree,
        intakeDate: course.intakeDate,
        entryScore: course.entryScore,
        fees: course.fees,
      },
    });
  };

  const handleContactAIEC = () => {
    navigate("/formpage");
  };

  return (
    <>
      <Container>
        {filteredPrograms ? (
          <>
            <DataCount size="1.5em" bold>
              Found {filteredPrograms.length} programs according to your criteria in USA.
            </DataCount>
            <GridContainer>
              {filteredPrograms.map((program, index) => (
                <ProgramCard key={index}>
                  <ProgramTitle>{program.university}</ProgramTitle>
                  <ProgramDetails>
                    <p>
                      <FontAwesomeIcon icon={faBook} />{" "}
                      <strong>{program.title}</strong>
                    </p>
                    {/* <p>
                      <FontAwesomeIcon icon={faFileAlt} /> {program.Certified}
                    </p> */}
                    <p>
                      <FontAwesomeIcon icon={faMapMarkerAlt} /> {program.location}
                    </p>
                    <p>
                      <FontAwesomeIcon icon={faEarth} /> World Ranking:{" "}
                      {program.worldRanking}
                    </p>
                    <p>
                      <FontAwesomeIcon icon={faCalendarAlt} />  Intake:{" "}
                      {program.intakeDate}
                    </p>
                    <p>
                      <FontAwesomeIcon icon={faStar} /> Entry Score:{" "}
                      {program.entryScore}
                    </p>
                    <p>
                      <FontAwesomeIcon icon={faDollarSign} /> Fees: 
                      {/* <ContactLink onClick={handleContactClick}>
                        {program.fees}
                      </ContactLink> */}
                    </p>
                  </ProgramDetails>
                  <ViewDetailsButton onClick={() => handleViewDetails(program)}>
              View details
            </ViewDetailsButton>
          </ProgramCard>
              ))}
            </GridContainer>
          </>
        ) : (
          <DataCount size="1.5em" bold>
            No available programs for the selected criteria.
          </DataCount>
        )}
      </Container>

    </>
  );
};

// Styled-components for styling
const Container = styled.div`
  margin-top: 100px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DataCount = styled.p`
  font-size: ${(props) => props.size || "1.1em"};
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  margin-bottom: 20px;
  text-align: center;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  justify-items: center;
`;

const ProgramCard = styled.div`
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  max-width: 350px;
`;

const ProgramTitle = styled.h3`
  font-size: 1em;
  color: #333;
  margin-bottom: 10px;
`;

const ProgramDetails = styled.div`
  text-align: left;
  margin-top: 10px;

  p {
    margin: 4px 0;
    font-size: 0.8em;
    color: #555;

    svg {
      margin-right: 8px;
      color: #007bff;
    }
  }
`;

const ContactLink = styled.span`
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: #0056b3;
  }
`;

const ViewDetailsButton = styled.button`
  margin-top: 15px;
  padding: 10px 25px;
  font-size: 0.9em;
  color: black;
  background-color: #ffffff;
  border: black 1px solid;
  border-radius: 20px;
  cursor: pointer;
  display: block;
  width: 100%;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;

  &:hover {
    background-color: #0056b3;
    color: #fff;
  }
`;

export default CourseUsa;